import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import zh from '@/assets/js/lang/zh';
import en from '@/assets/js/lang/en';
import bahasa from '@/assets/js/lang/bahasa';
import original from "@/assets/js/lang/original";
import { setCookie, getCookie, BASEURL } from '@/assets/js/tool';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import taAdmin from '@/plugin/ta-admin';
import * as echarts from 'echarts';
import JsonExcel from 'vue-json-excel';
import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import idLocale from 'element-ui/lib/locale/lang/id';

Vue.use(taAdmin);
Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.component('downloadExcel', JsonExcel);
Vue.prototype.$bus = new Vue()

const i18n = new VueI18n({
  // locale: sessionStorage.locale ? sessionStorage.locale : 'zh-CN', // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  //yes后面的zh-CN来自bj42578
  locale: sessionStorage.original==='yes'?sessionStorage.originalLanguage :(sessionStorage.locale ? sessionStorage.locale : "zh-CN"), // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  /*messages: {
        'zh-CN': zh,   // 中文语言包
        'en-US': en,    // 英文语言包
        'bahasa':bahasa,  // 印尼语言包
    }*/
  messages: {
    'zh-CN': {
      ...zh,
      ...zhLocale,
    }, // 中文语言包
    'en-US': {
      ...en,
      ...enLocale,
    }, // 英文语言包
    'bahasa-Indonesia': {
      ...bahasa,
      ...idLocale,
    }, // 印尼语言包
    "original-text": {
      ...original,
      ...enLocale,
    }, // 印尼语言包
    silentTranslationWarn: true,
  },
});
locale.i18n((key, value) => i18n.t(key, value));

router.beforeEach((to, from, next) => {
  let cache = JSON.parse(localStorage.getItem('cacheChamber'));
  //有用户信息
  if (cache) {
    if (cache.adminData) {
      //是从登录页进的 直接往下走
      if (to.path === '/login') {
        next();
      }
    }
    //    无用户信息
  } else {
    //去往的页面是登录页
    if (to.path === '/login') {
      // next('/login')
      console.log('去路', to.path);
      next();
      console.log('无登录信息，请登录');
      //去往的页面不是登录页
    } else {
      console.log('请先登录');
      next('/login');
    }
  }
  //chrome
  document.body.scrollTop = 0;
  //firefox
  document.documentElement.scrollTop = 0;
  //safari
  window.pageYOffset = 0;
  next();
  /*if(to.name!="questionDetail"){
        if(!getCookie("id") && to.name!="lawyer" && to.name!="home" && to.name!="wxlogin"){
            //next({path:'*'})
            //解除总用户拦截
            next()
        }else{
            next()
        }
    }else{
        next()
    }*/
});

router.afterEach((to, from) => {
  document.title = to.meta.title;
  if (to.meta.title) {
    let title = to.meta.title.split('|');
    document.title = sessionStorage.locale == 'en-US' ? title[1] : title[0];
  }
  // console.log('title',title)
  // console.log('sessionStorage.locale',sessionStorage.locale)
  // console.log('documentTitle',document.title)
  switch (sessionStorage.locale){
    case 'zh-CN':
      document.title = to.meta.title
      break
    case 'en-US':
      document.title = to.meta.enTitle
      break
    case 'bahasa':
      document.title = to.meta.idTile
      break
  }
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
