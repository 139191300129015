const temptData = {
  systemPermission: 1,
  messagePermission: 1,
  userPermission: 1,
  administratorPermission: 1,
  financePermission: 1,
  contentPermission: 1,
  postPermission: 1,
};

// 如果没有本地缓存
if (localStorage.getItem('cacheChamber')) {
  var data = JSON.parse(localStorage.getItem('cacheChamber')).adminData;
} else {
  var data = temptData;
}

const menu = [
  {
    path: '/home',
    // path: "/menu/AllContent",
    // title: "系統管理|System Management",
    titleGroup: {
      title: '系統管理',
      enTitle: 'System Management',
      idTitle: 'Manajemen Sistem',
    },
    icon: 'el-icon-s-marketing',
    // code: 0,
    code: data.systemPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'menu',
    children: [
      {
        // title: "全部|All",
        titleGroup: {
          title: '全部',
          enTitle: 'All Data',
          idTitle: 'Semua',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/AllContent',
      },
      {
        // title: "法律问答|Law QA",
        titleGroup: {
          title: '法律问答',
          enTitle: 'Law QA',
          idTitle: 'Kuis hukum',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/LawQA',
      },
      {
        // title: "视频在线|Video Online",
        titleGroup: {
          title: '视频在线',
          enTitle: 'Video Online',
          idTitle: 'Video daring',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/VideoOnline',
      },
      {
        // title: "合同下载|Contract Download",
        titleGroup: {
          title: '合同下载',
          enTitle: 'Contract Download',
          idTitle: 'Unduhan kontrak',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/ContractDownload',
      },
      {
        // title: "跨境须知|Cross Board",
        titleGroup: {
          title: '跨境须知',
          enTitle: 'Cross Board',
          idTitle: 'Pemberitahuan lintas batas',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/CrossBoard',
      },
      {
        // title: "商务合作|Commerce Cooperation",
        titleGroup: {
          title: '商务合作',
          enTitle: 'Commerce Cooperation',
          idTitle: 'Kerjasama Bisnis',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/CommerceCoop',
      },
      {
        // title: "会员捐款|Members' Donation",
        titleGroup: {
          title: '会员缴费',
          enTitle: 'Membership payment',
          idTitle: 'Pembayaran keanggotaan',
        },
        icon: 'el-icon-location',
        code: 100,
        path: '/menu/MemberDonation',
      },
    ],
  },
  {
    path: '/MessagerMenu',
    // title: "消息管理|Message Management",
    titleGroup: {
      title: '消息管理',
      enTitle: 'Message Management',
      idTitle: 'Manajemen pesan',
    },
    // code: 10,
    code: data.messagePermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'MessagerMenu',
    icon: 'el-icon-s-comment',
    children: [
      /*{
                path: "/MessagerMenu/SystemMessage",
                title: "系统消息|System Message",
                titleGroup:{
                    title: "系统消息|System Message",
                    enTitle: "System Message",
                    idTitle: "Sistem Informasi",
                },
                icon: "el-icon-location",
                code: 100
            },*/
      {
        path: '/MessagerMenu/GroupMessage',
        // title: "用户群发|Group Message",
        titleGroup: {
          title: '用户群发',
          enTitle: 'Group Message',
          idTitle: 'Grup pengguna',
        },
        icon: 'el-icon-location',
        code: 101,
      },
    ],
  },
  {
    path: '/UserMenu',
    // title: "用户管理|User Management",
    titleGroup: {
      title: '用户管理',
      enTitle: 'User Management',
      idTitle: 'Manajemen pengguna',
    },
    // code: 20,
    code: data.userPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'UserMenu',
    icon: 'el-icon-s-custom',
    children: [
      {
        path: '/UserMenu/UserManagement',
        // title: "会员管理|Member Management",
        titleGroup: {
          title: '会员管理',
          enTitle: 'Member Management',
          idTitle: 'Manajemen Anggota',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/UserMenu/UserGroup',
        // title: "会员管理|Member Management",
        titleGroup: {
          title: '用户分组',
          enTitle: 'User Group',
          idTitle: 'Pengelompokan pengguna',
        },
        icon: 'el-icon-location',
        code: 200,
      },
    ],
  },
  {
    path: '/AdminMenu',
    // title: "管理员管理|Admin Management",
    titleGroup: {
      title: '管理员管理',
      enTitle: 'Admin Management',
      idTitle: 'Manajemen pengurus',
    },
    // code: 20,
    code: data.administratorPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'AdminMenu',
    icon: 'el-icon-location',
    children: [
      {
        path: '/AdminMenu/Account',
        // title: "账号列表|Account List",
        titleGroup: {
          title: '管理员列表',
          enTitle: 'Admins List',
          idTitle: 'Daftar pengurus',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/AdminMenu/Operation',
        // title: "操作记录|Operation Record",
        titleGroup: {
          title: '操作记录',
          enTitle: 'Operation History',
          idTitle: 'Catatan operasi',
        },
        icon: 'el-icon-location',
        code: 101,
      },
    ],
  },
  {
    path: '/ContentMenu',
    // title: "内容管理|Content Management",
    titleGroup: {
      title: '内容管理',
      enTitle: 'Content Management',
      idTitle: 'Manajemen konten',
    },
    // code: 20,
    code: data.contentPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'ContentMenu',
    icon: 'el-icon-notebook-2',
    children: [
      {
        path: '/ContentMenu/MainPage',
        // title: "主页管理|MainPage Management",
        titleGroup: {
          title: '主页管理',
          enTitle: 'MainPage Management',
          idTitle: 'Manajemen halaman rumah',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/ContentMenu/SensitiveManagement',
        // title: "敏感词管理|Sensitive Words Management",
        titleGroup: {
          title: '敏感词管理',
          enTitle: 'Sensitive Words Management',
          idTitle: 'Manajemen kata sensitif',
        },
        icon: 'el-icon-location',
        code: 200,
      },
    ],
  },
  {
    path: '/PostMenu',
    // title: "帖子管理|Post Management",
    titleGroup: {
      title: '帖子管理',
      enTitle: 'Post Management',
      idTitle: 'Manajemen pos',
    },
    // code: 20,
    code: data.contentPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'PostMenu',
    icon: 'el-icon-notebook-2',
    children: [
      {
        path: '/PostMenu/UserPost',
        // title: "成员帖子管理|Users' Posts Management",
        titleGroup: {
          title: '成员帖子管理',
          enTitle: "Users' Posts Management",
          idTitle: 'Manajemen Posting Anggota',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/PostMenu/ReceivePost',
        // title: "接受帖子管理|Receive Posts Management",
        titleGroup: {
          title: '接受帖子管理',
          enTitle: 'Receive Posts Management',
          idTitle: 'Menerima manajemen pos',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/PostMenu/ReportPost',
        // title: "举报帖子管理|Report Posts Management",
        titleGroup: {
          title: '举报帖子管理',
          enTitle: 'Report Posts Management',
          idTitle: 'Laporkan manajemen pos',
        },
        icon: 'el-icon-location',
        code: 200,
      },
    ],
  },
  {
    path: '/FinanceMenu',
    // title: "财务管理|Finance Management",
    titleGroup: {
      title: '财务管理',
      enTitle: 'Finance Management',
      idTitle: 'Manajemen keuangan',
    },
    // code: 20,
    code: data.contentPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'FinanceMenu',
    icon: 'el-icon-notebook-2',
    children: [
      {
        path: '/FinanceMenu/ActivityPayment',
        // title: "活动缴费|Activity Payment",
        titleGroup: {
          title: '活动缴费',
          enTitle: 'Activity Payment',
          idTitle: 'Pembayaran aktivitas',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      {
        path: '/FinanceMenu/MembershipFee',
        // title: "会员捐款|Members\' Donation",
        titleGroup: {
          title: '会员缴费',
          enTitle: 'Membership payment',
          idTitle: 'Pembayaran keanggotaan',
        },
        icon: 'el-icon-location',
        code: 200,
      },
      /*{
        path: '/FinanceMenu/Donation',
        // title: "会员捐款|Members\' Donation",
        titleGroup: {
          title: '会员捐款',
          enTitle: "Members' Donations",
          idTitle: 'Sumbangan Anggota',
        },
        icon: 'el-icon-location',
        code: 200,
      },*/
      /*{
        path: '/FinanceMenu/InvoiceRequest',
        // title: "会员捐款|Members\' MembershipFee",
        titleGroup: {
          title: '发票申请',
          enTitle: 'Invoice Request',
          idTitle: 'Permohonan Faktur',
        },
        icon: 'el-icon-location',
        code: 200,
      },*/
    ],
  },
  /*{
    path: '/IndustryReport',
    // title: "行业报告|Industry Report",
    titleGroup: {
      title: '行业报告',
      enTitle: 'Industry Report',
      idTitle: 'Laporan Industri',
    },
    // code: 20,
    code: data.contentPermission === 0 ? 0 : 1, //    0: 不可见    1:可见
    name: 'IndustryReport',
    icon: 'el-icon-notebook-2',
    children: [
      {
        path: '/IndustryReport/ReportManagement',
        // title: "会员报告|Members' Reports",
        titleGroup: {
          title: '会员报告',
          enTitle: "Members' Reports",
          idTitle: 'Laporan anggota',
        },
        icon: 'el-icon-location',
        code: 200,
      },
    ],
  },*/
];

export default menu;
