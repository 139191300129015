export default {
  localLanguage: 'Chinese',
  SysName: '商会咨询后台管理系统',
  AttorneyAthena: '智汇律神',
  ChamberVersion: '商会版',

  //    登录
  Login: '登录',
  Logout: '退出登录',
  ReLogin: '重新登录',
  Juzi: '巨紫',
  InputAccount: '请输入账户',
  InputPassword: '请输入密码',
  InputPassword2: '请再次输入密码',
  ErrorPassword2: '两次输入密码不一致!',
  ForgetPassword: '忘记密码',
  ConfirmPassword: '请确认密码',
  InputValidCode: '请输入验证码',
  GetValidCode: '获取验证码',
  Second: '秒',
  BackLogin: '返回登录页面',
  EmptyAccount: '账户不能为空',
  Remember: '记住密码',
  PlsLogin: '请先登录',
  Back: '后退',
  EmailNotExist: '邮箱不存在',
  SysBusy: '系统繁忙，请稍后再试',
  AccountForbid: '你的账号已被禁止',
  PasswordError: '账号或密码错误',
  NoAccount: '该账号不存在',
  ModifyMe:
    '<p>针对本账号的改动需要重新登录才能生效</p><p>是否确认改动并重新登录？</p>',

  // 忘记密码
  InputRight: '请输入下方数字',
  EmptyNum: '数字不能为空',
  GetVCode: '获取验证码',
  NewPassword: '新密码',
  EmailVCode: '邮箱验证码',
  WrongVCode: '验证码不正确',
  EmptyVCode: '验证码不能为空',
  SecondTry: '秒后重试',
  SuccessfulChange: '更改密码成功',
  PasswordLengthTips: '密码长度在 8 到 16 个字符',

  //    个人中心
  PersonalCenter: '个人中心',
  AdminName: '姓名',
  InvolveCountry: '经商涉及国家',

  //    目录
  MyAccount: '账号',
  Data: '流量',

  SystemManagement: '系统管理',
  LawQA: '法律问答',
  VideoOnline: '视频在线',
  ContractDownload: '合同下载',
  CrossBoard: '跨境须知',

  MessageManagement: '消息管理',
  SystemMessage: '系统消息',
  GroupMessage: '群发消息',

  UserManagement: '用户管理',
  MemberManagement: '会员管理',

  AdminManagement: '管理员管理',
  AccountList: '账号列表',
  OperationRecord: '操作记录',

  ContentManagement: '内容管理',
  MainPageManagement: '主页管理',
  PostManagement: '帖子管理',
  FinanceManagement: '财务管理',
  IndustryReport: '行业报告',

  //    系统管理 全部
  Annual: '年度',
  Quarterly: '季度',
  Monthly: '月度',
  Weekly: '本周',
  Daily: '当日',
  Coupon: '红包抵扣',
  Expense: '消费',
  Download: '下载',
  DragUpload: '将文件拖到此处 或',
  ClickUpload: '点击上传',
  UploadLimitPDF: '只能上传pdf文件，且不超过200MB',
  Publish: '发布量',
  Receive: '接受量',
  CoopSuccess: '合作成功',
  DonationPopulation: '捐款人数',
  DonationFund: '捐赠资金',
  Buyers: '买家区',
  Sellers: '卖家区',
  CommerceCoop: '寻合作伙伴',
  AllExpense: '全部消费',
  ConfirmUpload: '确认上传',
  SelectFile2: '选择文件',
  UploadFile: '上传文件',
  UploadFileTips:
    '如需变更已上传的文件，请选择新文件后上传文件，则旧文件将被新文件自动覆盖',

  //    消息管理
  SendMsg: '发送消息',
  Title: '标题',
  Content: '内容',
  TitleLong: '标题过长',
  WordLimit: '字数限制：',
  ReadMore: '点击阅读全文',
  InputTitle: '请输入标题',
  InputContent: '请输入内容',
  NoData: '暂无数据',

  // 会员管理
  AllData: '全部',
  Initiate: '启用',
  Banned: '禁用',
  ExceptionData: '异常',
  Unreviewed: '未审核',
  CompanyName: '公司名称',
  ContactPerson: '联系人',
  NickName: '昵称',
  RealName: '真实姓名',
  ContactNum: '联系电话',
  CompanyAddress: '公司地址',
  InfoMsg: '通知消息',
  Status: '状态',
  Operation: '操作',
  Edit: '编辑',
  DeleteData: '删除',
  AddPerson: '添加用户',
  EditPerson: '编辑用户',
  CreateTime: '创建时间',
  UploadData: '导入',
  File: '文件',
  ExportExcelData: '导出',
  Search: '搜索',
  InitialPassword: '初始密码',
  Confirm: '确定',
  Cancle: '取消',
  SelectFile: '请选择文件',
  EmptyEmail: '邮箱不能为空',
  ValidEmail: '请输入有效邮箱',
  EmailExist: '该邮箱已被注册',
  EmailAccountExist: '邮箱或帐户已被注册',
  FillTel: '联系电话不能为空',
  TelExist: '该联系电话已被注册',
  TelNum: '电话必须为数字',
  EmptyName: '姓名不能为空',
  EmptyPassword: '初始密码不能为空',
  EmptyPassword2: '请重复初始密码',
  UnpairPassword: '两次密码输入不一致',
  EmptyInfo: '通知消息不能为空',
  ComfirmDelete: '确定删除吗？',
  ComfirmDeleteRow: '若删除此账号，该邮箱不可再次申请创建账号，是否确认删除？',
  ComfirmInit: '确定启用此用户吗？',
  ComfirmBan: '确定禁用此用户吗？',
  XlsOnly: '仅支持xls/xlsx格式，且邮箱不可重复',
  M5BOnly: '上传模板大小不能超过 5MB!',
  UploadSuccess: '上传成功',
  UploadCancel: '已取消上传',
  DownloadModel: '下载模板',
  ConfirmExcel:
    '请确保上传的Exel格式是使用的系统提供的模板，否则系统会出错，请严格遵守',
  Freeze: '冻结',

  //    管理员管理
  Authority: '权限',
  Password: '密码',
  Visiable: '可见',
  Hide: '不可见',
  EditOnly: '可编辑但不可删',
  AllRight: '所有权限',
  Management: '管理',
  OperType: '操作类型',
  OperContent: '操作内容',
  OperDate: '操作日期',
  OperAdmin: '操作人',
  Add: '添加',
  ChooseDate: '选择日期',
  AddSuccess: '添加成功',
  NoRight: '暂无权限',

  //    内容管理
  Chamber: '商会',
  System: '系统',
  Avatar: '头像',
  Name: '名称',
  UserAgreement: '用户协议',
  Privacy: '隐私政策',
  AboutUs: '关于我们',
  Carousel: '轮播图展示',
  alert:
    '如果商会认为其会员可以不遵守智汇律神PC端供应商默认公示的使用规则，\n' +
    '包括但不限于《当事人用户平台服务协议》、《隐私政策》及在智汇律神\n' +
    '系统内公示的弹框和提示信息。则商会可以在系统供应商交付的后台自主\n' +
    '更改其系统的《当事人用户平台服务协议》、《隐私政策》等，也有权要\n' +
    '求系统供应商按照其设计更改系统内公示的弹框和提示信息。此种情况下\n' +
    '，系统在商会当地国运营的合规性问题由商会单独承担责任。',
  HasFile: '已有文件 点击下载',
  FinalTime: '最后更改时间为',
  ImgOnly: '图片格式只能为 【jpg】【png】【jpeg】!',
  MBOnly: '上传头像图片大小不能超过 2MB!',
  EmptyInput: '请输入名称',
  OneFileOnly: '只允许上传一个文件',
  FileOnly: '文件格式只能为 【pdf】!',

  //     帖子管理
  ProductType: '产品类型',
  ProductRequest: '产品要求',
  ProductDetail: '产品详情',
  reviewTime: '审核时间',
  ReportingTime: '举报时间',
  Pass: '通过',
  AllDelete: '本系统删除',
  Refuse: '拒绝',
  Auditing: '待审核',
  Passed: '已通过',
  AutoRefused: '已过期',
  ManRefused: '已拒绝',
  AutoPass: '自动通过',
  ManPass: '人工通过',
  Sensitive: '有敏感词',
  ProjectIntro: '项目介绍',
  CooperationMethod: '合作方式',
  MyRequest: '我的要求',
  RevokeAll: '从所有商会撤回该贴',
  RevokeThis: '从本商会删除该帖',
  RevokeThis2: '从所有系统撤回该帖',
  Pre: '上一条',
  After: '下一条',
  Negotiable: '价格可谈',
  NonNegotiable: '价格不可谈',
  Deleted: '已删除',
  InvalidReport: '该举报已无效',
  Withdraw: '用户撤销举报',
  DoInvalidReport: '举报无效',
  ReportReason: '举报理由',
  ConfirmDelete: `<p>确定删除该帖吗？</p>
    <p>删除后客户端和后台都无法再次看到该帖</p>
    <p>确认删除请点确认</p>`,
  ConfirmDelete2: `<p>确定撤回吗？</p>
    <p>撤回后所有商会都无法再次看到该帖</p>
    <p>确认撤回请点确认</p>`,
  RefusePost: '拒绝帖子',
  From: '来自',
  ValidTill: '有效期至',
  PublishTime: '发布时间',
  WithdrawAlert: '撤销后该报告将彻底消失，是否确认撤销?',
  RefuseAlert: '拒绝后该报告将彻底消失，是否确认拒绝?',
  Attention: '提示',
  Withdrew: '撤销成功',
  Member: '(成员)',
  CoopRequest: '合作要求',
  TendPrice: '意向价格',
  SellPrice: '销售价格',
  DefaultRefuse: '默认显示举报理由',
  Canceled: '已取消',
  RefuseReason: '拒绝理由',
  NonNecessary: '非必填',
  PostType: '帖子类型',
  TheEnd: '已经到头了',

  //     财务管理
  CreateActivity: '创建活动',
  PayStart: '报名开始时间',
  PayEnd: '报名结束时间',
  Paying: '报名中',
  Paid: '报名结束',
  PayCancel: '活动取消',
  PayCancelPop: '是否确定取消？',
  Time: '时间',
  Organizer: '发起人',
  PayAmount: '报名金额',
  PayPerson: '报名人',
  PayTime: '报名时间',
  Detail: '详情',
  CancelActivity: '取消活动',
  ActivityEnd: '活动结束',
  Close: '不取消',
  AdditionInfo: '补充',
  ActiveStart: '活动开始时间',
  ActiveEnd: '活动截止时间',
  PayMethod: '费用用途',
  PerPayment: '个人缴费金额',
  Theme: '主题',
  Address: '地址',
  People: '参加人员',
  Customize: '自定义',
  FreeActivity: '免费活动',
  EmptyTheme: '主题不能为空',
  EmptyAddress: '地址不能为空',
  EmptyGroup: '参加人员不能为空',
  EmptyDate: '日期不能为空',
  EmptyContent: '内容不能为空',
  EmptyMoney: '金额不能为空,或您可选择自定义',
  InputMoney: '请输入金额',
  Customized: '已选择自定义',
  AllGroup: '全选所有会员',
  EditActivity: '编辑活动',
  Refund: '退款',
  RefundAll: '全部退款',
  AllDonation: '总捐款收入：',
  AllIncome: '总收入：',
  Population: '参加人数：',
  CheckNotes: '查看留言',
  DonationTime: '捐款时间',
  DonationNotes: '捐款留言',
  DonationAmount: '捐款金额',
  DonationUnit: '捐款单位',
  DonationPerson: '捐款人',
  DonationAddress: '捐款人地址',
  AllSame: '设置年费',
  FreeTime: '新用户免费使用时长',
  PayPeople: '缴费人数：',
  Latest: '最新',
  Expire: '有效期至',
  Invoice: '发票',
  SetSuccessful: '设置成功',
  ActivityInvoice: '活动发票',
  MembershipInvoice: '会员缴费发票',
  DonationInvoice: '会员捐款发票',
  Invoiced: '已开发票',
  ApplyPeople: '申请人',
  NeedInvoice: '需要发票',
  Note: '备注',
  SetInvoiced: '该申请状态已标为已开发票',
  Day: '天',
  Month: '月',
  Year: '年',
  SetMembershipFee: '会费设置',
  StartTime: '开始日期',
  EndTme: '结束日期',
  Day30: '缴费时段不可以超过1个月',
  SixMonths: `<p>会员缴纳时段一旦确认，会确保会员的使用便利，6个月内后台将无法再次设置会费缴纳时段</p>`,
  CurrentPeriod: '当前的会费缴纳时段是：',
  ConfirmToConfirm: '<p>若时间无误，点击确认来确认设置。</p>',
  Reset: '重新设置',
  ResetTime: '已设置过会费，下次可设置时间：',

  //     行业报告
  Writer: '撰写人',
  Reject: '已驳回',
  Cancel: '撤销',
  RevokeReport: '撤回',
  BriefIntroduction: '简介',

  // 敏感词管理
  Delete: '删除',
  AddSensitiveWords: '添加敏感词',
  SensitiveWords: '敏感词',
  Creator: '创建人',
  EmptySensitive: '敏感词不能为空',

  Ssystem: '商务互通',
  Fsystem: '法务互通',

  // 用户分组
  ManageGroup: '管理组',
  BatchMoveTo: '批量移至',
  BatchMoveToTip: '请先选择多个会员账号，再使用批量移至功能',
  Group: '分组',
  MoveTo: '移至',
  NotPayTip: '该会员尚未缴纳会费，您可以等会费缴纳时间段过后再移动',
  GroupNamePlaceholder: '请输入组名',
  RemarkPlaceholder: '请添加备注',
  PeopleNumber: '人数',
  MoveUp: '上移',
  MoveDown: '下移',
  AddGroup: '新建组',
  Verify: '确认',
  DeleteGroupTip: '确认删除分组？',
  DeleteSuccess: '删除成功',
  GroupNameEmpty: '组名不能为空',
  RemarkEmpty: '备注不能为空',
  AddSuccessTip: '新增成功',
  EditSuccessTip: '编辑成功',
  OperationSuccessTip: '操作成功',
  SelectGroupTip: '请选择分组',

  // 用户群发
  HairpinMessage: '私发消息',
  EditSend: '编辑并重新发送',
  AnewSend: '重新发送',
  Publicity: '公开',
  PublisherName: '发布人姓名',
  AdministratorName: '管理员姓名',
  CreationDate: '创建日期',
  RevocationTip: '撤回意味着该消息将在系统内彻底消失，是否确认撤回？',
  VerifyRevocation: '确认撤回',
  CancelRevocation: '取消撤回',
  RevocationSuccess: '撤回成功',
  GoBack: '返回',
  Picture: '图片',
  Attachments: '附件',
  DragFile: '将文件拖到此处，或',
  Extension: '支持扩展名',
  SendPossessor: '发送至所有人',
  SendSpecificGroup: '发送至特定组',
  Send: '发送',
  PicturePreview: '图片预览',
  PictureRestrict: '最多上传6张图片',
  FileRestrict: '最多上传3个文件',
  SendSuccess: '发送成功',
  MyMember: '会员',
  SelectedMember: '已选会员',
  PleaseSelectMember: '请选择会员',
  GroupType: '组别',
  PleaseSelect: '请选择',
  ReadFull: '阅读全文',
  Publisher: '发布者',
  Off: '关闭',
  MemberStatus: '会员已读未读状态',
  SelectGroup: '选择分组',
  Read: '已读',
  Unread: '未读',
};
