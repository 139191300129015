export default {
  localLanguage: 'English',
  SysName: 'Chamber of Commerce consulting back-stage management system',
  AttorneyAthena: 'AttorneyAthena',
  ChamberVersion: 'For chamber of Commerce',

  //    登录
  Login: 'Login',
  Logout: 'Logout',
  ReLogin: 'Re-login',
  Juzi: 'Juzi',
  InputAccount: 'Input account',
  InputPassword: 'Input Password',
  InputPassword2: 'Verify Password',
  ErrorPassword2: 'Wrong password',
  ForgetPassword: 'Forget Password',
  ConfirmPassword: 'Confirm password',
  InputValidCode: 'Input verification code',
  GetValidCode: 'Send Code',
  Second: 'second',
  BackLogin: 'Login',
  EmptyAccount: 'Account cannot be empty',
  Remember: 'Remember Name',
  PlsLogin: 'Pls login first',
  Back: 'Back',
  EmailNotExist: 'Email not Exist',
  SysBusy: 'The system is busy, please try again later',
  AccountForbid: 'Your account has been banned',
  PasswordError: 'Incorrect username or password',
  NoAccount: 'This account does not exist',
  ModifyMe:
    ' ' +
    '<p>The changes made to this account require a re-login to take effect.</p> ' +
    '<p>Are you sure you want to confirm the changes and log in again?？</p>',

  // 忘记密码
  InputRight: 'Please enter the number below',
  EmptyNum: 'Number cannot be empty',
  GetVCode: 'Get verification code',
  NewPassword: 'New Password',
  EmailVCode: 'Email verification code',
  WrongVCode: 'Wrong verification code',
  EmptyVCode: 'Verification code cannot be empty',
  SecondTry: 'seconds to retry',
  SuccessfulChange: 'Change password successfully',
  PasswordLengthTips: 'Password length should be between 8 and 16 characters',

  //    个人中心
  PersonalCenter: 'Account',
  AdminName: 'Name',
  InvolveCountry: 'Business-Involve-Country',

  //    菜单
  MyAccount: 'Account',
  Data: 'Data',

  SystemManagement: 'System Management',
  LawQA: 'LawQA',
  VideoOnline: 'Video Online',
  ContractDownload: 'Contract Download',
  CrossBoard: 'Cross Board',

  MessageManagement: 'Message Management',
  SystemMessage: 'System Message',
  GroupMessage: 'Group Message',

  UserManagement: 'User Management',
  MemberManagement: 'Member Management',

  AdminManagement: 'Admin Management',
  AccountList: 'Account List',
  OperationRecord: 'Operation Record',

  ContentManagement: 'Content Management',
  MainPageManagement: 'MainPage Management',
  PostManagement: 'Post Management',
  FinanceManagement: 'Finance Management',
  IndustryReport: 'Industry Report',

  //    系统管理 全部
  Annual: 'Annual ',
  Quarterly: 'Quarterly ',
  Monthly: 'Monthly ',
  Weekly: 'Weekly ',
  Daily: 'Daily ',
  Coupon: 'Coupon',
  Expense: 'Expense',
  Download: 'Download',
  DragUpload: 'Drag the file here or',
  ClickUpload: 'Click to upload',
  UploadLimitPDF: 'PDF only and less than 200MB',
  Publish: 'Publish Amount',
  Receive: 'Accept Amount',
  CoopSuccess: 'Cooperation succeed',
  DonationPopulation: 'Number of Donors',
  DonationFund: 'Donation Fund',
  Buyers: 'Find Buyers',
  Sellers: 'Find Sellers',
  CommerceCoop: 'Find Partners',
  AllExpense: 'All Expense',
  ConfirmUpload: 'Confirm upload',
  SelectFile2: 'Select File',
  UploadFile: 'Upload File',
  UploadFileTips:
    'If you need to change an already uploaded file, please select the new file and upload it, and the old file will be automatically replaced by the new file',

  //    消息管理
  SendMsg: 'Send Message',
  Title: 'Title',
  Content: 'Content',
  TitleLong: 'Title is too long',
  WordLimit: 'Word limited：',
  ReadMore: 'Read More',
  InputTitle: 'Please enter title',
  InputContent: 'Please enter content',
  NoData: 'No Data',

  // 会员管理
  AllData: 'All',
  Initiate: 'Initiated',
  Banned: 'Banned',
  ExceptionData: 'Exception',
  Unreviewed: 'Unreviewed',
  CompanyName: 'Company Name',
  ContactPerson: 'Contact person',
  NickName: 'Nick Name',
  RealName: 'Real Name',
  ContactNum: 'Contact Number',
  CompanyAddress: 'Company Address',
  InfoMsg: 'Additional Massage',
  Status: 'Status',
  Operation: 'Operation',
  Edit: 'Edit',
  DeleteData: 'Delete',
  AddPerson: 'Add User',
  EditPerson: 'Edit User',
  CreateTime: 'Create Time',
  UploadData: 'Upload',
  File: 'File',
  ExportExcelData: 'Export',
  Search: 'Search',
  InitialPassword: 'Initial Password',
  Confirm: 'Confirm',
  Cancle: 'Cancle',
  SelectFile: 'Please select file',
  EmptyEmail: 'Email cannot be empty',
  ValidEmail: 'Please input valid Email',
  EmailExist: 'This Email already exists',
  EmailAccountExist: 'Email or account already exists',
  FillTel: 'Contact number cannot be empty',
  TelExist: 'This contact number already exists',
  TelNum: 'Contact number must be number',
  EmptyName: 'Contact name cannot be empty',
  EmptyPassword: 'Initial password cannot be empty',
  EmptyPassword2: 'Please input password again',
  UnpairPassword: 'The two entered passwords do not match',
  EmptyInfo: 'Message cannot be empty',
  ComfirmDelete: 'Do you confirm deleting this user?',
  ComfirmDeleteRow:
    'If you delete this account, the email cannot be used to create another account again. Are you sure you want to proceed with the deletion?',
  ComfirmInit: 'Do you confirm initiating this user?',
  ComfirmBan: 'Do you confirm banning this user?',
  XlsOnly: 'xls/xlsx only, and Email cannot be repeated',
  M5BOnly: 'Upload template size cannot exceed 5MB!',
  UploadSuccess: 'Uploaded successfully',
  UploadCancel: 'Upload canceled',
  DownloadModel: 'Download Excel template',
  ConfirmExcel:
    'Please make sure that the uploaded Excel format is a template provided by the system, otherwise the system will make an error, please strictly abide by it',
  Freeze: 'Frozen',

  //    管理员管理
  Authority: 'Authority',
  Password: 'Password',
  Visiable: 'Visible',
  Hide: 'Hide',
  EditOnly: 'Edit Only',
  AllRight: 'Full Authority',
  Management: 'Management',
  OperType: 'Operation Type',
  OperContent: 'Operation',
  OperDate: 'Date',
  OperAdmin: 'Operator',
  Add: 'Add',
  ChooseDate: 'Select Date',
  AddSuccess: 'Added successfully',
  NoRight: 'No permission',

  //    内容管理
  Chamber: 'Chamber',
  System: 'System',
  Avatar: 'Avatar',
  Name: 'Name',
  UserAgreement: 'User Agreement',
  Privacy: 'Privacy Policy',
  AboutUs: 'About Us',
  Carousel: 'Carousel',
  alert:
    '如果商会认为其会员可以不遵守智汇律神PC端供应商默认公示的使用规则，\n' +
    '包括但不限于《当事人用户平台服务协议》、《隐私政策》及在智汇律神\n' +
    '系统内公示的弹框和提示信息。则商会可以在系统供应商交付的后台自主\n' +
    '更改其系统的《当事人用户平台服务协议》、《隐私政策》等，也有权要\n' +
    '求系统供应商按照其设计更改系统内公示的弹框和提示信息。此种情况下\n' +
    '，系统在商会当地国运营的合规性问题由商会单独承担责任。',
  HasFile: 'File Exist, Download',
  FinalTime: 'Update in ',
  ImgOnly: 'The image format can only be 【jpg】【png】【jpeg】!',
  MBOnly: 'The uploaded image size cannot exceed 2MB!',
  EmptyInput: 'Please enter a name',
  OneFileOnly: 'Only one file is allowed to be uploaded',
  FileOnly: 'The file format can only be 【pdf】!',

  //     帖子管理
  ProductType: 'Product Type',
  ProductRequest: 'Product Requirements',
  ProductDetail: 'Product Details',
  reviewTime: 'Audit Time',
  ReportingTime: 'Report Time',
  Pass: 'Pass',
  AllDelete: 'Delete from this system',
  Refuse: 'Refuse',
  Auditing: 'Pending Review',
  Passed: 'Passed',
  AutoRefused: 'Expired',
  ManRefused: 'Rejected',
  AutoPass: 'Auto-Pass',
  ManPass: 'Manual Passage',
  Sensitive: 'Sensitive Word',
  ProjectIntro: 'Project Introduction',
  CooperationMethod: 'Cooperation Methods',
  MyRequest: 'My request',
  RevokeAll: 'Revoke from all System',
  RevokeThis: 'Delete from this System',
  RevokeThis2: 'Withdraw this post from all systems',
  Pre: 'Pre',
  After: 'After',
  Negotiable: 'Negotiable',
  NonNegotiable: 'Non-Negotiable',
  Deleted: 'Deleted',
  InvalidReport: 'Report Invalided',
  Withdraw: 'Report been withdrew',
  DoInvalidReport: 'Invalid',
  ReportReason: 'Report Reason',
  ConfirmDelete: `<p>Do you confirm deleting the post？</p>
    <p>The post will be hidden to all after deletion</p>
    <p>Press confirm if you are</p>`,
  ConfirmDelete2: `<p>Are you sure you want to withdraw？</p>
    <p>After withdrawal, all chambers of commerce will no longer be able to see this post</p>
    <p>Please click 'confirm' to proceed</p>`,
  RefusePost: 'Reject Post',
  From: 'From',
  ValidTill: 'Valid until',
  PublishTime: 'Release time',
  WithdrawAlert:
    'After revocation, the report will disappear completely, whether to confirm the revocation?',
  RefuseAlert:
    'After rejection, the report will disappear completely, whether to confirm the rejection?',
  Attention: 'Attention',
  Withdrew: 'Withdrawal successfully',
  Member: '(Member)',
  CoopRequest: 'Cooperation requirements',
  TendPrice: 'Intention price',
  SellPrice: 'Selling price',
  DefaultRefuse: 'Show reporting reason by default',
  Canceled: 'Cancelled',
  RefuseReason: 'Refuse reason',
  NonNecessary: 'Optional',
  PostType: 'Post Type',
  TheEnd: 'It is the end.',

  //     财务管理
  CreateActivity: 'Create Activity',
  PayStart: 'Registration start time',
  PayEnd: 'Registration End time',
  Paying: 'Registration Open',
  Paid: 'Registration closed',
  PayCancel: 'Activity canceled',
  PayCancelPop: 'Are you sure to cancel?',
  Time: 'Time',
  Organizer: 'Organizer',
  PayAmount: 'Registration fee',
  PayPerson: 'Number of payees',
  PayTime: 'Registration period',
  Detail: 'Detail',
  CancelActivity: 'Cancel Activity',
  ActivityEnd: 'Activity ended',
  Close: 'Keep Activity',
  AdditionInfo: 'Additional Info',
  ActiveStart: 'Start time',
  ActiveEnd: 'Activity ended',
  PayMethod: 'Expense Purpose',
  PerPayment: 'Personal payment amount',
  Theme: 'Theme',
  Address: 'Address',
  People: 'Participants',
  Customize: 'Customize',
  FreeActivity: 'Free Activity',
  EmptyTheme: 'Theme cannot be empty',
  EmptyAddress: 'Address cannot be empty',
  EmptyGroup: 'Participants cannot be empty',
  EmptyDate: 'Date cannot be empty',
  EmptyContent: 'Content cannot be empty',
  EmptyMoney: 'Amount cannot be empty',
  InputMoney: 'Please enter the amount',
  Customized: 'Custom selected',
  AllGroup: 'Select all members',
  EditActivity: 'Edit Activity',
  Refund: 'Refund',
  RefundAll: 'Refund All',
  AllDonation: 'All Donation income：',
  AllIncome: 'All Income：',
  Population: 'Registration count：',
  CheckNotes: 'Check Notes',
  DonationTime: 'Donation Time',
  DonationNotes: 'Donation Notes',
  DonationAmount: 'Donation Amount',
  DonationUnit: 'Donation Unit',
  DonationPerson: 'Donor',
  DonationAddress: "Donor's Address",
  AllSame: 'Annual Fee',
  FreeTime: 'Free trial',
  PayPeople: 'Number of participants：',
  Latest: 'Latest',
  Expire: 'Valid until',
  Invoice: 'Invoice',
  SetSuccessful: 'Setting Successful',
  ActivityInvoice: 'Event Payment Invoice',
  MembershipInvoice: 'Membership Fee Invoice',
  DonationInvoice: 'Donation Payment Invoice',
  Invoiced: 'Invoice Issued',
  ApplyPeople: 'Applicant',
  NeedInvoice: 'Need invoice',
  Note: 'Note',
  SetInvoiced:
    'The status of this application has been marked as "Invoice Issued".',
  Day: 'Day(s)',
  Month: 'Month(s)',
  Year: 'Year',
  SetMembershipFee: 'Membership Fee Settings',
  StartTime: 'Start Date',
  EndTme: 'End Date',
  Day30: 'The payment period must not exceed one month',
  SixMonths: `<p>Once the membership payment period is confirmed, it will ensure convenience for members' use, and within 6 months, the backend will no longer be able to set the membership payment period.</p>`,
  CurrentPeriod: 'The current membership payment period is：',
  ConfirmToConfirm:
    "<p>If the time is correct, click 'Confirm' to confirm the settings.</p>",
  Reset: 'Reset',
  ResetTime: 'Membership fee set, next available setting time：',

  //     行业报告
  Writer: 'Writer',
  Reject: 'Rejected',
  Cancel: 'Cancel',
  RevokeReport: 'Revoke',
  BriefIntroduction: 'Abstract',

  //敏感词管理
  Delete: 'Delete',
  AddSensitiveWords: 'Add Sensitive Word',
  SensitiveWords: 'Sensitive Words',
  Creator: 'Creator',
  EmptySensitive: 'Sensitive cannot be empty',

  Ssystem: 'Commercial connectivity system',
  Fsystem: 'Legal connectivity system',

  // 用户分组
  ManageGroup: 'Manage Group',
  BatchMoveTo: 'Batch Move To',
  BatchMoveToTip:
    'Please select multiple member accounts first, then use the batch move to function',
  Group: 'Group',
  MoveTo: 'Move To',
  NotPayTip:
    'This member has not yet paid the membership fee. You can move them after the membership fee payment period',
  GroupNamePlaceholder: 'Please enter group name',
  RemarkPlaceholder: 'Please add remarks',
  PeopleNumber: 'Number of People',
  MoveUp: 'Move Up',
  MoveDown: 'Move Down',
  AddGroup: 'Add Group',
  Verify: 'Verify',
  DeleteGroupTip: 'Confirm deletion of group?',
  DeleteSuccess: 'Deletion Successful',
  GroupNameEmpty: 'Group name cannot be empty',
  RemarkEmpty: 'Remarks cannot be empty',
  AddSuccessTip: 'Added successfully',
  EditSuccessTip: 'Edited successfully',
  OperationSuccessTip: 'Operation successful',
  SelectGroupTip: 'Please select a group',

  // 用户群发
  HairpinMessage: 'Private Message',
  EditSend: 'Edit and Resend',
  AnewSend: 'Resend',
  Publicity: 'Public',
  PublisherName: "Publisher's Name",
  AdministratorName: "Administrator's Name",
  CreationDate: 'Creation Date',
  RevocationTip:
    'Recalling means the message will be permanently removed from the system. Are you sure you want to recall it?',
  VerifyRevocation: 'Confirm Recall',
  CancelRevocation: 'Cancel Recall',
  RevocationSuccess: 'Recall Successful',
  GoBack: 'Back',
  Picture: 'Image',
  Attachments: 'Attachment(s)',
  DragFile: 'Drag the file here, or ',
  Extension: 'Available file extensions',
  SendPossessor: 'Send to Everyone',
  SendSpecificGroup: 'Send to Specific Group',
  Send: 'Send',
  PicturePreview: 'Image Preview',
  PictureRestrict: 'Upload up to 6 images',
  FileRestrict: 'Upload up to 3 files',
  SendSuccess: 'Send Successful',
  MyMember: 'Member',
  SelectedMember: 'Selected Members',
  PleaseSelectMember: 'Please select members',
  GroupType: 'Group',
  PleaseSelect: 'Please select',
  ReadFull: 'Read Full Article',
  Publisher: 'Publisher',
  Off: 'Close',
  MemberStatus: 'Member Read/Unread Status',
  SelectGroup: 'Select Group',
  Read: 'Read',
  Unread: 'Unread',
};
