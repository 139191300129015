/*
 * 顶部菜单栏
 */

import router from "@/router/index";

const loginRouter = [{
  path: "/login",
  meta: { title: "登录|Login", view: false }
}];

// const localView = JSON.parse(localStorage.getItem('viewRouters')).paths
if (localStorage.getItem("viewRouters")) {
  var viewRoute = JSON.parse(localStorage.getItem("viewRouters")).paths;
} else {
  var viewRoute = loginRouter;
}

// var viewRoute = JSON.parse(localStorage.getItem('viewRouters')).paths || loginRouter
//默认页
/*const defaultRoute = {
  path: "/menu/AllContent",
  meta: {
    title: "全部|All"
  }
};*/
const defaultRoute = viewRoute[0];

export default {
  namespaced: true,
  state: {
    //菜单列表
    headerMenu: [],
    //路由初始值
    currentRoute: defaultRoute.path
  },
  actions: {
    //登录
    login(context, value) {
      if (router.currentRoute.path === viewRoute[0].path) {
        const route = {
          path: viewRoute[0].path,
          title: viewRoute[0].meta.title
        };
        state.headerMenu = [route];
      } else {
        state.headerMenu = [];
        router.push(defaultRoute);
      }
    }
  },
  mutations: {
    //增加
    add(state, router) {
      const menus = state.headerMenu;
      const route = {
        path: router.path,
        title: router.meta.title
      };
      if (route.path !== "/login" && router.path !== "/WaitingPage") {
        state.headerMenu = [route];
        let isExtra = menus.find(item => item.path === route.path);
        if (!isExtra) {
          menus.push(route);
        }
      }
      state.currentRoute = route.path;
    },
    //删除
    sub(state, path) {
      let index = state.headerMenu.findIndex(item => item.path === path);
      let isSelf = path === state.currentRoute;
      if (isSelf) {
        if (index === 0) {
          state.currentRoute = state.headerMenu[1].path;
        } else {
          state.currentRoute = state.headerMenu[index - 1].path;
        }
        router.push({ path: state.currentRoute });
      }
      state.headerMenu.splice(index, 1);
    },
    //清空
    clear(state) {
      if (router.currentRoute.path === viewRoute[0].path) {
        const route = {
          path: viewRoute[0].path,
          title: viewRoute[0].meta.title
        };
        state.headerMenu = [route];
      } else {
        state.headerMenu = [];
        router.push(defaultRoute);
      }
    }
  }
};
