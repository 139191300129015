import { Notification, MessageBox, Message, Loading } from 'element-ui';
import qs from 'qs';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { setCookie, getCookie, BASEURL } from '@/assets/js/tool';

//POST传参序列化
axios.defaults.baseURL = BASEURL;
// 封装文件上传的头信息

function hex2b64(h) {
  var b64map =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var b64padchar = '=';
  var i;
  var c;
  var ret = '';
  for (i = 0; i + 3 <= h.length; i += 3) {
    c = parseInt(h.substring(i, i + 3), 16);
    ret += b64map.charAt(c >> 6) + b64map.charAt(c & 63);
  }
  if (i + 1 == h.length) {
    c = parseInt(h.substring(i, i + 1), 16);
    ret += b64map.charAt(c << 2);
  } else if (i + 2 == h.length) {
    c = parseInt(h.substring(i, i + 2), 16);
    ret += b64map.charAt(c >> 2) + b64map.charAt((c & 3) << 4);
  }
  while ((ret.length & 3) > 0) ret += b64padchar;
  return ret;
}

JSEncrypt.prototype.encryptLong = function(string) {
  var k = this.getKey();
  try {
    var ct = '';
    //RSA每次加密117bytes，需要辅助方法判断字符串截取位置
    //1.获取字符串截取点
    var bytes = new Array();
    bytes.push(0);
    var byteNo = 0;
    var len, c;
    len = string.length;
    var temp = 0;
    for (var i = 0; i < len; i++) {
      c = string.charCodeAt(i);
      if (c >= 0x010000 && c <= 0x10ffff) {
        //特殊字符，如Ř，Ţ
        byteNo += 4;
      } else if (c >= 0x000800 && c <= 0x00ffff) {
        //中文以及标点符号
        byteNo += 3;
      } else if (c >= 0x000080 && c <= 0x0007ff) {
        //特殊字符，如È，Ò
        byteNo += 2;
      } else {
        // 英文以及标点符号
        byteNo += 1;
      }
      if (byteNo % 117 >= 114 || byteNo % 117 == 0) {
        if (byteNo - temp >= 114) {
          bytes.push(i);
          temp = byteNo;
        }
      }
    }
    //2.截取字符串并分段加密
    if (bytes.length > 1) {
      for (var i = 0; i < bytes.length - 1; i++) {
        var str;
        if (i == 0) {
          str = string.substring(0, bytes[i + 1] + 1);
        } else {
          str = string.substring(bytes[i] + 1, bytes[i + 1] + 1);
        }
        var t1 = k.encrypt(str);
        ct += t1;
      }
      if (bytes[bytes.length - 1] != string.length - 1) {
        var lastStr = string.substring(bytes[bytes.length - 1] + 1);
        ct += k.encrypt(lastStr);
      }
      return hex2b64(ct);
    }
    var t = k.encrypt(string);
    var y = hex2b64(t);
    return y;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

export function getUUID() {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  );
  var uuid = [];
  var i;
  var radix = chars.length;
  var r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  for (i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (Math.random() * radix);
      uuid[i] = chars[r];
    }
  }
  return uuid.join('');
}

function getJzflag() {
  var jzflag = {
    tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
      ? JSON.parse(localStorage.getItem('cacheChamber')).adminData.tenantCode
      : null,
    terminal: 'chamberback',
    rand: getUUID(),
    milliseconds: Date.now(),
  };
  var toStr = JSON.stringify(jzflag);
  toStr = encrypt(toStr);
  return toStr;
}

var $http = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  timeout: 30000,

  transformRequest: [
    function(data) {
      return data;
    },
  ],
});
// export function fetch(url, params) {
//     return new Promise((resolve, reject) => {
//         axios.get(url, { params: params},{timeout: 10000} )
//         .then(response => {
//             resolve(response.data);
//         }, err => {
//             reject(err);
//         })
//         .catch((error) => {
//             reject(error)
//         })
//     })
// }
//参数加密 json: json对象
function paramesEncryption(jsonObj) {
  let arr = Object.entries(jsonObj).sort();
  let str1 = '';
  arr.forEach((item, index) => {
    str1 += item[0] + '=' + item[1] + '&';
  });
  str1 = str1.slice(0, str1.length - 1);
  str1 = str1
    .replace(/\s+/g, '')
    .replace(/\r\n/g, '')
    .replace(/\n/g, '')
    .replace(/\!/g, '')
    .replace(/\'/g, ''); //过滤掉所有空格
  str1 = encodeURIComponent(str1).toLowerCase();
  let keyArray = CryptoJS.enc.Utf8.parse(getCookie('svCode'));
  let ivArray = CryptoJS.enc.Utf8.parse(getCookie('svCode'));
  let sv = CryptoJS.DES.encrypt(str1, keyArray, {
    iv: ivArray,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  sv = sv
    .replace(/\+/gi, '$$$$')
    .replace(/\//gi, '@@')
    .toLowerCase();
  return sv;
}
//获取页签
// function getSK(){
//     return postData('/user/getSK', {},false)
// }

//post
export function postData(url, data, jmflag, contentType) {
  //参数加密
  if (jmflag) {
    if (getCookie('id')) {
      data.userId = getCookie('id');
      data.token = getCookie('token');
    }
    // if(!getCookie("svCode")){
    //     return getSK().then((res)=>{
    //         setCookie("svCode",res.data);
    //         data.sv = paramesEncryption(data);
    //         return sendRequst(url,data);
    //     });
    // }
    else {
      data.sv = paramesEncryption(data);
      return sendRequst(url, data, contentType);
    }
  } else {
    return sendRequst(url, data, contentType);
  }
}

//加密
export function encrypt(string) {
  var publicKey =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAijYy5SvcGkf6+l13ESoX5ggy9Ig//L1NFsQoXQPTaEI0lm3c4Bh4n7iikICjnwANednNxewKUnZN8jMNISPqJMLizIzH75hqd98Kh8uwBI+2P1UOLBC0IDwWabaidurDW32vjsF0CWrzTul1mEippgjVxvwXfd8LK/TA1clvvfptByNIbnERFioMONjbBJWLP0kplIjd7NCpH8kXaOa9GnSCa1SVRnfc+O5cR4hV1vhzVaacunm0d2ttZgtmrnkYvnlWQXjwvC/iGPQ5Wv7MApQSOu9C+EwoTtGKT4yEdr6qaja/h5VMZ3pu3CNZ/3B3Ciw2LvLptXRtF+RnSSRxfwIDAQAB';
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  var encrypted = encrypt.encryptLong(string);
  return encrypted;
}

function sendRequst(url, data, contentType) {
  const formDataContentType = 'application/x-www-form-urlencoded;charset=utf-8';
  // 处理源语言
  let localLanguage = '';
  if (sessionStorage.getItem('locale') === 'original-text')
    localLanguage = 'non';
  else localLanguage = sessionStorage.translang;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': contentType ? contentType : formDataContentType,
          'local-language': localLanguage,
          originalLanguage: sessionStorage.originalLanguage,
          terminal: 'chamberback',
          tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
            ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
                .tenantCode
            : null,
          jzflag: getJzflag(),
        },
        transformRequest: [
          function(data, headers) {
            const formDataContentType =
              'application/x-www-form-urlencoded;charset=utf-8';
            if (headers['Content-Type'] == formDataContentType) {
              let ret = '';
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  '=' +
                  encodeURIComponent(data[it]) +
                  '&';
              }
              return ret;
            } else {
              return JSON.stringify(data);
            }
          },
        ],
        timeout: 30000,
      })
      .then(
        (response) => {
          if (
            '/user/loginByThirdtype' == url ||
            '/mobile/pay/queryOrderStatus' == url ||
            '/user/sendVerifyCode' == url
          ) {
            resolve(response.data);
          } else if (response.data.code == '200') {
            resolve(response.data);
          } else {
            Message({
              message: response.data.msg,
              type: 'error',
              offset: '100',
              duration: 2000,
            });
            reject(response.data);
          }
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 登录 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 提交登录信息
   */
  login(params) {
    return postData('/LoginDo/loginByAccount', params, true);
  },

  /**
   * 获取系统名称
   */
  getSysName(params) {
    return postData('/LoginDo/getSystemByCurrentAdmin', params, true);
  },

  /**
   * 获取验证码（4位数）
   */
  getVerifyCode4(params) {
    return postData('/athenaUserDO/getSecurityCode', params, true);
  },

  /**
   * 获取验证码（6位数）
   */
  getVerifyCode6(params) {
    return postData('/athenaUserDO/sendVerifyCode', params, true);
  },

  /**
   * 修改登录密码
   */
  UpdateAdminPassword(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/AdminManagementDO/updateUserById',
      data: params,
    });
  },

  /**
   * 根据tenantCode查询租户信息
   */
  getChamberLanguage(params) {
    return postData(
      '/controlCenterChamberDO/getChamberLanguage',
      params,
      false
    );
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 个人中心 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取个人信息
   */
  getAdminDetail(params) {
    return postData('/AdminManagementDO/getAdminDetail', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 系统管理 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取PV UV  type: 1法律问答  2视频在线  3合同下载  4跨境须知
   */
  getPVUV(params) {
    return postData('/SystemManagementDO/getViewManagement', params, true);
  },

  /**
   * 获取消费
   */
  getConsumption(params) {
    return postData(
      '/SystemManagementDO/getConsumptionManagement',
      params,
      true
    );
  },

  /**
   * 获取合同下载
   */
  getDownload(params) {
    return postData(
      '/SystemManagementDO/getContractDownloadManagement',
      params,
      true
    );
  },

  /**
   * 获取业务类型 匹配 id 和名称
   */
  mapBizIdName(params) {
    return postData('/indexDO/getBusinessListByBusinessIds', params, true);
  },

  /**
   * 获取业务类型
   */
  getBizTypeList(params) {
    return postData('/indexDO/getBizTypeList', params, true);
  },

  /**
   * 获取所有国家
   */
  getAllCountries(params) {
    return postData('/indexDO/getCountries', params, true);
  },

  /**
   * 获取可视化帖子
   */
  getPostsManagement(params) {
    return postData('/SystemManagementDO/getPostManagement', params, true);
  },

  /**
   * 获取可视化捐款
   */
  getDonationManagement(params) {
    return postData('/SystemManagementDO/getDonateManagement', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 消息管理 系统消息 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取消息列表
   */
  getMsgList(params) {
    // return postData('/MessageManagementDO/getMessages', params,true)
    return postData('/MessageManagementDO/getBatchMessages', params, true);
  },

  /**
   * 发送消息
   */
  sendMsg(params) {
    return postData('/MessageManagementDO/sendMessage', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 会员管理 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  //   启用1   禁用2  异常3

  /**
   * 获取用户列表
   */
  getUerList(params) {
    return postData('/UserManagementDO/getUsers', params, true);
  },

  /**
   * 修改用户
   */
  editUser(params) {
    return postData('/UserManagementDO/editUser', params, true);
  },

  /**
   * 删除用户
   */
  deleteUser(params) {
    return postData('/UserManagementDO/deleteUser', params, true);
  },

  /**
   * 添加用户
   */
  addUser(params) {
    return postData('/UserManagementDO/addUser', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 账号列表 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取管理员列表    permission: 0:不可见   1:可见   2: 可编辑不可删  3：所有权限
   */
  getAdmin(params) {
    return postData('/AdminManagementDO/getAdmin', params, true);
  },

  /**
   * 修改管理员
   */
  editAdmin(params) {
    return postData('/AdminManagementDO/editAdmin', params, true);
  },

  /**
   * 删除用户
   */
  deleteAdmin(params) {
    return postData('/AdminManagementDO/deleteAdmin', params, true);
  },

  /**
   * 添加管理员
   */
  addAdmin(params) {
    return postData('/AdminManagementDO/addAdmin', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 帖子管理 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取业务类型
   */
  getProductTypeIndex(params) {
    return postData('/LinkIndexDO/getProductTypeIndex', params, true);
  },

  /**
   * 获取自己商会成员发布的帖子
   */
  getMembershipPosts(params) {
    return postData('/PostManagementDO/getMembershipPosts', params, true);
  },

  /**
   * 获取接收到的帖子
   */
  getReceivePosts(params) {
    return postData('/PostManagementDO/getReceivePosts', params, true);
  },

  /**
   * 获取买家区和卖家区帖子详情
   */
  getBackEndPostDetail(params) {
    return postData('/PostManagementDO/getBackEndPostDetail', params, true);
  },

  /**
   * 获取买家区和卖家区帖子详情
   */
  getBackEndPostDetailAdmin(params) {
    return postData(
      '/PostManagementDO/getBackEndPostDetailAdmin',
      params,
      true
    );
  },

  /**
   * 撤销帖子 （本系统删除（接收的帖子/举报帖子）/ 全系统撤销（成员的帖子））
   */
  getDeleteById(params) {
    return postData('/PostManagementDO/deleteById', params, true);
  },

  /**
   * 举报帖子 举报无效
   */
  getUpdateId(params) {
    return postData('/ReportDO/updateById', params, true);
  },

  /**
   * 拒绝成员帖子/拒绝接收帖子/（通过成员帖子/通过接收帖子）
   */
  getUpdatePostById(params) {
    return postData('/PostManagementDO/updateById', params, true);
  },

  /**
   * 获取合作伙伴详情
   */
  getBackEndPartnerDetail(params) {
    return postData('/PostManagementDO/getBackEndPartnerDetail', params, true);
  },

  /**
   * 获取合作伙伴详情
   */
  getBackEndPartnerDetailAdmin(params) {
    return postData(
      '/PostManagementDO/getBackEndPartnerDetailAdmin',
      params,
      true
    );
  },

  /**
   * 获取被举报的帖子
   */
  getReportPosts(params) {
    return postData('/PostManagementDO/getReportPosts', params, true);
  },

  /**
   * 上一条下一条  成员帖子
   */
  preAfter1(params) {
    return postData(
      '/PostManagementDO/getUpOrDownPostForMembership',
      params,
      true
    );
  },

  /**
   * 上一条下一条  接收帖子
   */
  preAfter2(params) {
    return postData('/PostManagementDO/getUpOrDownForReceive', params, true);
  },

  /**
   * 上一条下一条  举报帖子
   */
  preAfter3(params) {
    return postData('/PostManagementDO/getUpOrDownForReport', params, true);
  },

  // -------------------------------------------------------------------------------行业报告------------------------------------

  /**
   * 获取行业报告列表
   */
  getReportList(params) {
    // getIndustryReportList
    return postData('/IndustryReportManagementDO/getReportList', params, true);
  },

  /**
   * 行业报告详情
   */
  getReportDetail(params) {
    return postData('/IndustryReportsDO/getIndustryReportDetail', params, true);
  },

  /**
   * 删除行业报告接口
   */
  getUpdateById(params) {
    return postData('/IndustryReportsDO/updateById', params, true);
  },

  /**
   * 拒绝行业报告接口
   */

  getAuditById(params) {
    return postData('/IndustryReportsDO/auditById', params, true);
  },

  /**
   * 对帖子进行审核
   */
  getReviewPost(params) {
    return postData('/PostManagementDO/reviewPost', params, true);
  },

  /**
   * 对举报进行审核
   */
  reviewReportPost(params) {
    return postData('/PostManagementDO/reviewPost', params, true);
  },
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>财务管理>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  /**
   * 获取活动缴费
   */
  getActivityPayment(params) {
    return postData('/FinancialManagementDO/getEventsList', params, true);
  },

  /**
   * 创建活动
   */
  createActivity(params) {
    return postData('/FinancialManagementDO/createEvent', params, true);
  },

  /**
   * 获取活动详情
   */
  getEventDetail(params) {
    return postData('/PaymentForEventsDO/getEventDetail', params, true);
  },

  /**
   * 编辑活动
   */
  editActivity(params) {
    return postData('/FinancialManagementDO/editEvent', params, true);
  },

  /**
   * 取消活动
   */
  cancelActivity(params) {
    return postData(
      '/FinancialManagementDO/updateCancelEvent',
      params,
      false,
      'application/json;charset=utf-8'
    );
  },

  /**
   * 获取缴费详情
   */
  getPaymentDetail(params) {
    return postData('/FinancialManagementDO/getPaymentList', params, false);
  },

  /**
   * 获取缴费详情
   */
  getPaymentDetailE(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'local-language': localLanguage,
        terminal: 'chamberback',
        originalLanguage: sessionStorage.originalLanguage,
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'get',
      url: BASEURL + '/FinancialManagementDO/getPaymentList',
      params: params,
      responseType: 'blob',
    });
  },

  /**
   * 获取全部会员捐款
   */
  getMembershipList(params) {
    return postData('/FinancialManagementDO/getMembershipList', params, true);
  },

  /**
   * 获取会员捐款
   */
  getChamberMember(params) {
    return postData(
      '/FinancialManagementDO/getMembershipInformation',
      params,
      true
    );
  },

  /**
   * 获取会员费开票
   */
  getMembershipInvoice(params) {
    return postData(
      '/FinancialManagementDO/getMembershipInvoice',
      params,
      true
    );
  },

  /**
   * 获取单个会员缴费记录
   */
  getPerMemberFeeList(params) {
    return postData('/userCenterDO/getMembershipFeeList', params, true);
  },

  /**
   * 编辑会员年费、免费时长
   */
  editGroup(params) {
    return postData('/FinancialManagementDO/editGroup', params, true);
  },

  /**
   * 获取会员捐款
   */
  /*getDonation(params) {
      return postData('/FinancialManagementDO/getDonationList', params, true);
  },*/
  getDonation(params) {
    return postData(
      '/FinancialManagementDO/getMembershipPayment',
      params,
      true
    );
  },

  /**
   * 获取活动开票
   */
  getEventInvoice(params) {
    return postData('/FinancialManagementDO/getEventInvoice', params, true);
  },

  /**
   * 获取会员费开票
   */
  getDonationInvoice(params) {
    return postData('/FinancialManagementDO/getDonationInvoice', params, true);
  },

  /**
   * 设置已开发票
   */
  setInvoiced(params) {
    return postData('/FinancialManagementDO/applyInvoice', params, true);
  },

  /**
   * 设置未开发票
   */
  setNoInvoiced(params) {
    return postData('/PaymentForEventsDO/applyInvoice', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 操作记录 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 添加管理员
   */
  // getOperation(params) {
  // 	return postData('/OperateManagementDO/getOperation', params, true);
  // },

  getOperation(params) {
    return postData(
      '/OperateManagementDO/getOperationList',
      params,
      false,
      'application/json;charset=utf-8'
    );
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 主页管理 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 获取主页内容
   */
  getMainPage(params) {
    return postData('/MainPageManagementDO/getMainPageInfo', params, true);
  },

  /**
   * 编辑主页内容
   */
  editMainPage(params) {
    return postData('/MainPageManagementDO/editMainPage', params, true);
  },

  /**
   *获取敏感词列表
   */
  getSensitiveWordList(params) {
    return postData('/SensitiveWordDO/getSensitiveWordList', params, true);
  },

  /**
   * 添加敏感词
   */
  addSensitiveWord(params) {
    return postData('/SensitiveWordDO/addSensitiveWord', params, true);
  },

  /**
   * 删除敏感词
   */
  deleteSensitiveWord(params) {
    return postData('/SensitiveWordDO/deleteSensitiveWord', params, true);
  },

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 上传会员管理EXCEL
   */
  uploadMembers(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'multipart/form-data',
        'local-language': localLanguage,
        terminal: 'chamberback',
        originalLanguage: sessionStorage.originalLanguage,
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/UserManagementDO/addExcel',
      data: params,
    });
  },

  /*uploadMembers(params){
        return postData('/UserManagementDO/addExcel', params, true,'multipart/form-data');
    },*/

  /**
   * 上传文件
   */
  uploadFile(url, formData, params) {
    let id = getCookie('id');
    let token = getCookie('token');
    if (id && token) {
      formData.append('userId', id);
      formData.append('token', token);
      params.userId = id;
      params.token = token;
      formData.append('sv', paramesEncryption(params));
    }
    return new Promise((resolve, reject) => {
      return $http
        .post(url, formData)
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 获取跨境须知列表
   */
  getListPageCrossBorder(params) {
    return postData(
      '/athena-consumer/crossBorderNoticeDO/getListPage',
      params,
      true
    );
  },

  /**
   * 数据统计（除开帖子以外的数据）
   */
  queryStatisticsData(params) {
    return postData(
      '/SystemManagementDO/queryStatisticsData',
      params,
      false,
      'application/json;charset=utf-8'
    );
  },

  /**
   * 数据统计(帖子)
   */
  queryStatisticsPostData(params) {
    return postData(
      '/SystemManagementDO/queryStatisticsPostData',
      params,
      false,
      'application/json;charset=utf-8'
    );
  },

  /**
   * 添加协议/覆盖协议
   */
  addProtocol(params) {
    return postData(
      '/controlCenterSystemProtocol/add',
      params,
      false,
      'application/json;charset=utf-8'
    );
  },

  /**
   * 获取系统用户协议、关于我们、用户隐私等信息列表
   */
  queryListBySystemId(params) {
    return postData(
      '/controlCenterSystemProtocol/queryListBySystemId',
      params,
      false
    );
  },

  /**
   * 获取用户协议、关于我们、用户隐私等信息
   */
  queryMsgById(params) {
    return requstBody('/controlCenterSystemProtocol/queryById', params, false);
  },

  /**
   * 用户分组列表
   */
  queryGroupList(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberRelevance/queryGroupList',
      data: params,
    });
  },

  /**
   * 分组成员列表
   */
  getGroupByChamberId(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberGroup/getGroupByChamberId',
      data: params,
    });
  },

  /**
   * 创建分组
   */
  createGroup(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberGroup/createGroup',
      data: params,
    });
  },

  /**
   * 编辑分组、上移、下移、长按拖拽排序
   */
  zikaUpdateByIdList(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberGroup/zikaUpdateByIdList',
      data: params,
    });
  },

  /**
   * 删除分组
   */
  zikaDeleteById(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberGroup/zikaDeleteById',
      data: params,
    });
  },

  /**
   * 移至分组、批量移至分组
   */
  moveToGroup(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberRelevance/save',
      data: params,
    });
  },

  /**
   * 获取群发消息列表
   */
  getBatchMessagesNew(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'get',
      url: BASEURL + '/MessageManagementDO/getBatchMessagesNew',
      params: params,
    });
  },

  /**
   * 群发消息、私发消息
   */
  sendMessageNew(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/MessageManagementDO/sendMessageNew',
      data: params,
    });
  },

  /**
   * 群发消息、私发消息（重新发送）
   */
  updateMessageByMessageId(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/MessageManagementDO/updateMessageByMessageId',
      data: params,
    });
  },

  /**
   * 消息详情
   */
  querySendMessageById(params) {
    return postData('/MessageManagementDO/querySendMessageById', params, true);
  },

  /**
   * 撤回
   */
  withdrawMessage(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'get',
      url: BASEURL + '/MessageManagementDO/withdrawMessage',
      params: params,
    });
  },

  /**
   * 会员已读未读状态
   */
  queryMessageIsReadList(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberRelevance/queryMessageIsReadList',
      data: params,
    });
  },

  /**
   * 扫描当前系统内无默认分组
   */
  scanNewMember(params) {
    // 处理源语言
    let localLanguage = '';
    if (sessionStorage.getItem('locale') === 'original-text')
      localLanguage = 'non';
    else localLanguage = sessionStorage.translang;
    return axios({
      headers: {
        'Content-Type': 'application/json',
        'local-language': localLanguage,
        originalLanguage: sessionStorage.originalLanguage,
        terminal: 'chamberback',
        tenantCode: JSON.parse(localStorage.getItem('cacheChamber'))
          ? JSON.parse(localStorage.getItem('cacheChamber')).adminData
              .tenantCode
          : null,
        jzflag: getJzflag(),
      },
      method: 'post',
      url: BASEURL + '/memberGroup/scanNewMember',
      data: params,
    });
  },
};
