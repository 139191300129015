import { getCookie, setCookie } from './tool';
// let moment = require("moment");

//获取语言
var lang = sessionStorage.locale ? sessionStorage.locale : '';
// mixin混入
export default {
  computed: {
    // 语言
    locale() {
      return sessionStorage.getItem('locale');
    }
  },
  data: function () {
    return {
      language: lang == "en-US" ? "English" : lang == "bahasa-Indonesia" ? "Bahasa" : lang == "zh-CN" ? "Chinese" : "non",
    };
  },
  created: function () { },
  methods: {
    //获取小时
    gethours: function (startDate, endDate) {
      var result = '';

      function addZero(num) {
        return num <= 9 ? '0' + num : '' + num;
      }
      var s = new Date(startDate);
      var e = new Date(endDate);
      var startTime =
        s.getFullYear() +
        '' +
        addZero(s.getMonth() + 1) +
        '' +
        addZero(s.getDate()) +
        '' +
        addZero(s.getHours()) +
        '' +
        addZero(s.getMinutes()) +
        '' +
        addZero(s.getSeconds());
      var endTime =
        e.getFullYear() +
        '' +
        addZero(e.getMonth() + 1) +
        '' +
        addZero(e.getDate()) +
        '' +
        addZero(e.getHours()) +
        '' +
        addZero(e.getMinutes()) +
        '' +
        addZero(e.getSeconds());
      if (startTime.length < 14 || endTime.length < 14) {
        layer.open({
          content:
            lang == 'en-US' ? 'Time format is incorrect' : '时间格式不正确',
          skin: 'msg',
          time: 2, //2秒后自动关闭
        });
      } else {
        var startArr = [
          startTime.substring(0, 4),
          startTime.substring(4, 6),
          startTime.substring(6, 8),
          startTime.substring(8, 10),
          startTime.substring(10, 12),
          startTime.substring(12, 14),
        ]; //切分开始时间为数组
        var endArr = [
          endTime.substring(0, 4),
          endTime.substring(4, 6),
          endTime.substring(6, 8),
          endTime.substring(8, 10),
          endTime.substring(10, 12),
          endTime.substring(12, 14),
        ]; //切分结束时间为数组
        var dateStart = new Date(
          startArr[0],
          startArr[1],
          startArr[2],
          startArr[3],
          startArr[4],
          startArr[5]
        ); //采用new Date(yyyy,mth,dd,hh,mm,ss);创建方式分别传入年月日时分秒
        var dateEnd = new Date(
          endArr[0],
          endArr[1],
          endArr[2],
          endArr[3],
          endArr[4],
          endArr[5]
        );
        var dateInterval = dateEnd.getTime() - dateStart.getTime(); //获取时间差毫秒
        //计算出相差天数
        var days = Math.floor(dateInterval / (24 * 60 * 60 * 1000));
        //计算小时数
        var hourLevel = dateInterval % (24 * 60 * 60 * 1000);
        var hours = Math.floor(hourLevel / (60 * 60 * 1000));
        //计算分钟数
        var minutesLevel = hourLevel % (60 * 60 * 1000);
        var minutes = Math.floor(minutesLevel / (60 * 1000));
        //计算秒数
        var seconds = Math.round((minutesLevel % (60 * 1000)) / 1000);

        if (days) {
          result += days + (lang == 'en-US' ? 'day' : '天');
        }
        if (hours) {
          result += hours + (lang == 'en-US' ? 'hours' : '小时');
        }
        if (minutes) {
          result += minutes + (lang == 'en-US' ? 'minutes' : '分钟');
        }
        if (seconds) {
          result += seconds + (lang == 'en-US' ? 'seconds' : '秒');
        }
        if (!result) {
          result = sessionStorage.locale == 'en-US' ? 'over' : '已结束';
        }
      }
      return result;
    },
    //计算两时间差转换成天小时分钟
    calcTime(sDate, eDate) {
      var result = '';

      var t = new Date(eDate).getTime() - new Date(sDate).getTime();

      var days = Math.floor(t / (24 * 3600 * 1000));

      var leave1 = t % (24 * 3600 * 1000); //计算天数后剩余的毫秒数

      var hours = Math.floor(leave1 / (3600 * 1000));

      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数

      var minutes = Math.floor(leave2 / (60 * 1000));

      if (days > 0) {
        result += days + (lang == 'en-US' ? ' day ' : '天');
      }
      if (days == 0 && hours == 0) {
        result = minutes + (lang == 'en-US' ? ' minutes ' : '分钟');
      } else {
        result +=
          hours +
          (lang == 'en-US' ? ' hours ' : '小时') +
          minutes +
          (lang == 'en-US' ? ' minutes ' : '分钟');
      }
      return result;
    },
    /**
     * 解决数字计算的精度丢失 乘法
     * @param  {[type]} arg1 [数字]
     * @param  {[type]} arg2 [数字]
     */
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split('.')[1].length;
      } catch (e) { }
      try {
        m += s2.split('.')[1].length;
      } catch (e) { }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      );
    },
    //过滤特殊字符
    filterSpecialCharacters(field) {
      return field;
    },
    compareDate(date1, date2) {
      var oDate1 = new Date(date1);
      var oDate2 = new Date(date2);
      if (oDate1.getTime() > oDate2.getTime()) {
        return true; //第一个大
      } else {
        return false; //第二个大
      }
    },
    /*        //获取格式化后的时间
        formatterData:function(millisecond,fmt){
            if(fmt){
                return moment(millisecond).format(fmt);
            }else{
                return moment(millisecond).format('YYYY-MM-DD HH:mm:ss');
            }
        },*/
    //获取游览器信息
    getBrowser(n) {
      var ua = navigator.userAgent.toLowerCase(),
        s,
        name = '',
        ver = 0;
      //探测浏览器
      (s = ua.match(/msie ([\d.]+)/))
        ? _set('ie', _toFixedVersion(s[1]))
        : (s = ua.match(/firefox\/([\d.]+)/))
          ? _set('firefox', _toFixedVersion(s[1]))
          : (s = ua.match(/chrome\/([\d.]+)/))
            ? _set('chrome', _toFixedVersion(s[1]))
            : (s = ua.match(/opera.([\d.]+)/))
              ? _set('opera', _toFixedVersion(s[1]))
              : (s = ua.match(/version\/([\d.]+).*safari/))
                ? _set('safari', _toFixedVersion(s[1]))
                : 0;
      function _toFixedVersion(ver, floatLength) {
        ver = ('' + ver).replace(/_/g, '.');
        floatLength = floatLength || 1;
        ver = String(ver).split('.');
        ver = ver[0] + '.' + (ver[1] || '0');
        ver = Number(ver).toFixed(floatLength);
        return ver;
      }
      function _set(bname, bver) {
        name = bname;
        ver = bver;
      }
      return n == 'n' ? name : n == 'v' ? ver : name + ver;
    },
    //将个人信息保存在cookies中
    saveUserInfo: function (data) {
      this.setCookie('nickname', data.nickname ? data.nickname : '');
      this.setCookie('mobile', data.mobile);
      this.setCookie('isLawyer', data.isLawyer);
      this.setCookie('avatar', data.avatar);
      this.setCookie('lawfirmname', data.lawfirmname);
      this.setCookie('gender', data.gender);
      this.setCookie('realname', data.realname ? data.realname : '');
      this.setCookie('token', data.token ? data.token : '');
      this.setCookie('payPassword', data.payPassword ? data.payPassword : ''); //是否设置支付密码
      this.setCookie('status', data.status ? data.status : ''); //律师认证状态
      this.setCookie('areaCode', data.areaCode ? data.areaCode : ''); //区号
      this.setCookie('countryId', data.countryId ? data.countryId : ''); //国别ID
      this.setCookie('id', data.id);
      // location.reload();
    },
    calcByte(text) {
      return text.replace(/[^\x00-\xff]/g, '**').length; //得到输入的字节
    },
    // 阅读全文
    readAll: function (e) {
      let parentNode = $(e.srcElement).parents('div.desc');
      parentNode.removeClass('text2');
      parentNode.find('span.view-all').hide();
      parentNode.find('span.close-more').show();
    },
    // 收起
    closeMore: function (e) {
      let parentNode = $(e.srcElement).parents('div.desc');
      parentNode.addClass('text2');
      parentNode.find('span.view-all').show();
      parentNode.find('span.close-more').hide();
    },
    // 拒绝
    refuse: function (success) {
      this.$prompt(
        this.$i18n.t('RejectAppointmentTip'),
        this.$i18n.t('RejectTheReminder'),
        {
          confirmButtonText: this.$i18n.t('Confirm'),
          inputType: 'textarea',
          inputPattern: /\S/,
          inputErrorMessage: this.$i18n.t('ReasonForRejection'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }
      ).then(function (value) {
        success && success(value);
      });
    },
    // 反馈
    feedback: function (id) {
      var _self = this;
      layer.open({
        className: 'popuo',
        shadeClose: false,
        style: 'width:300px;',
        content:
          '<div class="flex vertical">\
			    	<div class="title">' +
          this.$i18n.t('Feedback') +
          '</div>\
		    		<textarea placeholder="' +
          this.$i18n.t('YourFeedback') +
          '" id="comment"></textarea>\
		    		<div class="vw-100 flex"><span class="btn">' +
          this.$i18n.t('Confirm') +
          '</span></div>\
		    		<div class="vw-100 flex"><span class="back">' +
          this.$i18n.t('return') +
          '</span></div>\
			    </div>',
        anim: 'up',
        success: function (elem) {
          elem.querySelector('span.btn').onclick = function () {
            var comment = document.getElementById('comment').value;
            if (comment) {
              _self.getData({
                url: '/mobile/videoAppointment/partyEvaluateLawyer',
                params: {
                  id: id,
                  partyComment: comment,
                },
                success: function (data) {
                  layer.open({
                    className: 'popuo',
                    shadeClose: false,
                    style: 'width:300px;',
                    content:
                      '<div class="flex vertical"><img src="../static/img/icons/success.png"/><span class="mgt-10 gray-text">' +
                      this.$i18n.t('FeedbackSuccess') +
                      '</span></div>',
                    anim: 'up',
                    time: 2,
                    end: function () {
                      location.reload();
                    },
                  });
                },
              });
            } else {
              _self.errorTip(this.$i18n.t('YourFeedback'));
            }
          };
          elem.querySelector('span.back').onclick = function () {
            layer.closeAll();
          };
        },
      });
    },

    //输入框限制只能输入数字和小数点
    limitMoney: function (e) {
      //获取键盘输入的keyCode
      var keycode = Number(e.keyCode);
      //键盘上方数字键
      if (keycode >= 48 && keycode <= 57) {
        e.returnValue = true;
        //小数字键盘
      } else if (keycode >= 96 && keycode <= 105) {
        e.returnValue = true;
        //删除键和delete键和小数点
      } else if (
        keycode == 8 ||
        keycode == 46 ||
        keycode == 110 ||
        keycode == 190
      ) {
        e.returnValue = true;
        //除此之外，其他的不允许输入
      } else {
        e.returnValue = false;
      }
    },
    // 输入框屏蔽中文
    shieldIME: function (e) {
      if (/[\u4E00-\u9FA5]/i.test(e.target.value)) {
        e.target.value = '';
      }
    },
    //设置cookie
    setCookie: setCookie,
    //获取cookie
    getCookie: getCookie,
    //删除cookies
    delCookie: function (name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null)
        document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
    },
    //文件重命名
    resPhotoName: function (e, fileName, success) {
      var file = e.target.files[0] || e.dataTransfer.files[0];
      var ext = e.target.files[0].name.split('.')[1];
      var newfile = new File([file], fileName + '.' + ext, { type: file.type });
      var reads = new FileReader();
      var f = e.target.files[0];
      reads.readAsDataURL(f);
      reads.onload = function (e) {
        success && success(newfile, this.result);
        reads = '';
      };
    },
    errorTip: function (text) {
      this.$message({
        message: text,
        type: 'error',
        offset: '100',
        duration: 2000,
      });
    },
    successTip: function (text) {
      this.$message({
        message: text,
        type: 'success',
        offset: '100',
        duration: 2000,
      });
    },
    /**
     * 验证手机号
     * @param  {[type]} phone [description]
     * @return {[type]}       [description]
     */
    checkPhone: function (phone) {
      return /^1\d{10}$/.test(phone);
    },
    /**
     * 验证电话
     * @param  {[type]} tel [description]
     * @return {[type]}     [description]
     */
    checkTel: function (tel) {
      return /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(tel);
    },
    /**
     * 验证身份证
     * @param  {[type]} cardno [description]
     * @return {[type]}     [description]
     */
    checkIdCard: function (cardno) {
      return /^(\d{18,18}|\d{15,15}|\d{17,17}(x|X))$/.test(cardno);
    },
    checkPassword: function (password) {
      return /^.*(?=.{6,12})(?=.*\d)(?=.*[a-zA-Z@#$%&\*?]).*$/.test(password);
    },
    checkEmial: function (email) {
      return /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
        email
      );
    },
    /**
     * 验证金额
     * @param  {[type]} cardno [description]
     * @return {[type]}     [description]
     */
    checkPrice(val) {
      val = (val.toString()).replace(
        /^([1-9]\d*(\.[\d]{0,2})?|0(\.[\d]{0,2})?)[\d.]*/g,
        '$1'
      );
      //禁止录入整数部分超过7位
      val = (val.toString()).replace(/^([1-9]\d{6})\d*/g, '$1');
      //替换除了数字和.以外的字符
      val = (val.toString()).replace(/[^\d\.]/g, '');
      //第一个输入.  则自动变为0.
      val = (val.toString()).replace(/^\./g, '0.');
      //保证.只出现一次，而不能出现两次以上 2..    2.2.2
      val = (val.toString())
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
      //只能输入两个小数
      val = (val.toString()).replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      return val;
      //return /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(val);
    },
    // 验证必选字段{value:"",type:""} type : text为文本，tel为电话，phone为手机 idCard身份证 code验证码 password密码 email邮箱
    listenField: function (arr) {
      var flag = true;
      for (var i in arr) {
        if (!arr[i].value) {
          flag = false;
          break;
        }
        if (arr[i].type == 'phone') {
          flag = this.checkPhone(arr[i].value);
          if (!flag) break;
        }
        if (arr[i].type == 'code') {
          flag = (arr[i].value + '').length == 6;
          if (!flag) break;
        }
        if (arr[i].type == 'tel') {
          flag = this.checkTel(arr[i].value);
          if (!flag) break;
        }
        if (arr[i].type == 'idCard') {
          flag = this.checkIdCard(arr[i].value);
          if (!flag) break;
        }
        if (arr[i].type == 'password') {
          flag = this.checkPassword(arr[i].value);
          if (!flag) break;
        }
        if (arr[i].type == 'email') {
          flag = this.checkEmial(arr[i].value);
          if (!flag) break;
        }
      }
      return !flag;
    },
    //后端num => 前端权限
    numToAuthority(permission) {
      switch (permission) {
        case 0:
          return this.$t('Hide');
        case 1:
          return this.$t('Visiable');
        case 2:
          return this.$t('EditOnly');
        case 3:
          return this.$t('AllRight');
      }
    },
    //管理员后端num => 前端状态   0:启动  1：禁用
    numToStatusAdmin(status) {
      switch (status) {
        case 0:
          return this.$t('Initiate');
        case 1:
          return this.$t('Banned');
      }
    },
    //后端num => 前端状态   4:未审核  1：启用  2：禁止  3：异常  6：冻结
    numToStatus(status) {
      switch (status) {
        case 4:
          return this.$t('Unreviewed');
        case 1:
          return this.$t('Initiate');
        case 2:
          return this.$t('Banned');
        case 3:
          return this.$t('ExceptionData');
        case 6:
          return this.$t('Freeze');
      }
    },
    //后端num => 前端状态   0:启动  1：禁用  2：异常  3：冻结
    numToStatusLawyer(status) {
      switch (status) {
        case 3:
          return this.$t('Freeze');
        case 0:
          return this.$t('Initiate');
        case 1:
          return this.$t('Banned');
        case 2:
          return this.$t('ExceptionData');
      }
    },
    //后端num => 前端操作类型
    numToType(type) {
      switch (type) {
        case 0:
          return this.$t('DeleteData');
        case 1:
          return this.$t('Add');
        case 2:
          return this.$t('Edit');
      }
    },

    //      对 listObj 进行排序
    sortList(listObj) {
      listObj.sort(function (a, b) {
        var value1 = parseInt(a[0]);
        var value2 = parseInt(b[0]);
        return value1 - value2;
      });
    },

    // 处理时间
    handleTime(time) {
      return time.substr(0, 10);
    },

    //     前三个产品类型
    threeProduct(types) {
      let typeStr = ''
      if (types) {
        if (types.length > 3) {
          typeStr = types.slice(0, 3).join('，') + '...'
        } else {
          typeStr = types.join('，')
        }
        return typeStr
      }
    },

    //前三个帖子类型
    threePostType(type) {
      let typeObj = {
        0: this.$t('Buyers'),
        1: this.$t('Sellers'),
        2: this.$t('CommerceCoop'),
      }
      return typeObj[type]
    },

    //     规范Title显示
    formatTitle(row, column, cellValue) {
      if (!cellValue) return ''
      if (cellValue.length > 25) {       //最长固定显示10个字符
        return cellValue.slice(0, 25) + '...'
      }
      return cellValue
    },

    /**
     * 限制只能输入正整数
     **/
    checkNum(val) {
      val = (val.toString()).replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      if (val.indexOf('.') < 0 && val !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseInt(val);
      }
      console.log('val ->', val)
      return val
    },

  //   获取当前语言传给后端
    langToLanguage(){
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      return languageObj[locale]
    },

  },
};
