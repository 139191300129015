/*
 * 登录后储存用户信息
 */

export default {
    namespaced: true,
    state: {
        adminData:{
            id:'',
            avatarUrl:'',
            chamberId:'',
            firmId:'',
            username: "",
            token: "",
            password: "",
            mobile: "",
            name: "",
            stautus: 0,
            systemPermission: 1,
            messagePermission: 3,
            userPermission: 3,
            administratorPermission: 0,
            financePermission: 3,
            contentPermission: 0,
            ip: "",
            port: 0,
            userAgent: "",
            clientTags: "",
            mac: "",
            deviceUniqueNum: null,
            lastLoginAt: "",
            lastLoginIp: "",
            tencentUserSig: null

        },
    },
    mutations: {
        //成功登录才会进来这里
        SETUSER(state, param) {
            state.adminData = param
            //把登录信息放进localStorage，让vue放进浏览器cookie中
            window.sessionStorage.setItem('cacheChamber',JSON.stringify(param))
        },
    //    用来改变adminData的值
    //    外界只能通过该方法更改adminData
        INITUSER(state,param){
            state.adminData = param
        }
    }
};
