import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";
import { stringifyQuery, parseQuery } from "@/utils/query";

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    stringifyQuery: stringifyQuery, // 序列化query参数
    parseQuery: parseQuery, // 反序列化query参数
});

router.beforeEach((to, from, next) => {
    store.commit("menu/add", to);
    next();
});

export default router;
