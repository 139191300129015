// export const BASEURL = "http://localhost:8023/api/saas-api/athena-consumer";        //开发环境
// export const BASEURL = 'http://192.168.0.107:8182/saas-api/athena-consumer';
// export const BASEURL = 'http://192.168.0.222:8185/saas-api/athena-consumer'; // 稳定环境
// export const BASEURL = 'http://192.168.0.222:8185/saas-api/athena-consumer'; // 苏雄
// export const BASEURL = 'http://192.168.0.222:8186/saas-api/athena-consumer'; // 朝禹
export const BASEURL = 'http://chamberback.attorneyathena.com/saas-api/athena-consumer';  //SaaS新版生产环境，不要删

//获取cookie
export function getCookie(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  if (arr === document.cookie.match(reg)) return unescape(arr[2]);
  else return null;
}

//设置cookie
export function setCookie(name, value) {
  var Days = 0.18;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + '=' + escape(value) + ';expires=' + exp.toGMTString() + '; path=/';
}
