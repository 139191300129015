<!-- 骨架 -->
<template>
  <div id="layout">
    <!-- 顶栏 -->
    <header
      class="header"
      style="position: fixed;top: 0;bottom: 0;width: 1900px;z-index: 1000"
    >
      <!--<div class="left" :style="isCollapse? 'width:64px': 'width:251px' ">-->
      <div class="left">
        <!--<img :src="logo" alt="" v-if="isCollapse" class="foldImg">-->
        <!--<div v-if="!isCollapse" style="display: flex; vertical-align: middle;margin-left: 7px">-->
        <div style="display: flex; vertical-align: middle;margin-left: 7px">
          <div style="display: flex; vertical-align: middle;margin-left: 7px">
            <img
              :src="logo"
              alt=""
              class="foldImg"
            >
            <span>{{sysName}}</span>
          </div>
        </div>
      </div>
      <div class="center">
        <!--折叠按钮-->
        <!--<div class="center-left" @click="isCollapse = !isCollapse">
          <i class="el-icon-s-unfold" v-if="isCollapse"></i>
          <i class="el-icon-s-fold" v-else></i>
        </div>-->
        <div class="center-right">
          <!--语言选择-->
          <span class="mgr-10 lang-select">
            <el-select
              v-model="lang.value"
              @change="clang"
            >
              <el-option
                v-for="item in lang.options"
                :key="item.value"
                :label="item.label"
                style="z-index: 10000001"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <!--<el-button type="primary" class="header-icon">
            <i class="el-icon-message-solid"></i>
          </el-button>-->
          <!-- 主题切换 -->
          <el-button
            type="primary"
            class="header-icon"
            @click="change"
          >
            <i class="el-icon-s-opportunity"></i>
          </el-button>
          <!--<el-button type="primary" class="header-icon">
            <i class="el-icon-s-platform"></i>
          </el-button>-->
        </div>
      </div>
      <div class="right">
        <el-button
          type="text"
          @click="toPersonalCenter"
          style="color: white;font-size: 16px"
        >
          {{ $t('PersonalCenter') }}
        </el-button>
      </div>
    </header>
    <!-- 主体 -->
    <div class="center">
      <!-- 左侧菜单  -->
      <div
        class="side-bar"
      >
        <el-scrollbar style="height:100%">
          <el-menu
            unique-opened
            :default-active="activeTab"
            :collapse="isCollapse"
            style="position: fixed;top: 60px;bottom: 0;z-index: 1000;"
            class="el-menu-vertical-demo"
            router
          >
            <template v-for="sub in viewMenus">
              <el-submenu
                :index="sub.path"
                v-if="sub.children"
                :key="sub.path"
              >
                <template
                  slot="title"
                  v-show="sub.code === 1"
                >
                  <div @mouseenter="ifTooLong($event)" @mouseleave="mouseLeave($event)">
                  <!--<div @mouseover="ifTooLong($event)" @mouseout="mouseLeave($event)">-->
                    <i :class="sub.icon"></i>
                    <div style="display: inline-block;" :ref="sub.name">{{ titleName(sub.titleGroup) }}</div>
                  </div>
                  <!--<div style="display: inline-block" :ref="sub.name">{{ titleName(sub.titleGroup) }}</div>-->
                </template>
                <div>
                  <el-menu-item
                    :index="item.path"
                    v-for="item in sub.children"
                    :key="item.path"
                    :width="isCollapse? '64px':'251px' "
                  >
                    <div @mouseenter="ifTooLong($event)" @mouseleave="mouseLeave($event)">
                      <!--<div @mouseover="ifTooLong($event)" @mouseout="mouseLeave($event)">-->
                      <i :class="item.icon"></i>
                      <!--<div style="display: inline-block" :ref="item.path" @mouseover="ifTooLong($event)" @mouseout="mouseLeave($event)">{{ titleName(item.titleGroup) }}</div>-->
                      <div style="display: inline-block;" :ref="item.path">{{ titleName(item.titleGroup) }}</div>
                    </div>
                  </el-menu-item>
                </div>
              </el-submenu>
              <el-menu-item
                v-else
                :index="sub.path"
                :key="sub.path"
              >
                <i
                  :class="sub.icon"
                  style="float: right;"
                ></i>
                <span> {{ titleName(sub.titleGroup) }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
      </div>
      <!--<div class="container" style="margin:60px auto auto 230px">-->
      <div
        class="container"
        style=" margin-top:60px; width: 1910px;min-height: 848px"
        :style="isCollapse? 'margin-left:64px': 'margin-left:251px' "
      >
        <header
          class="header"
          style="position: fixed;top: 60px;z-index: 1000; display: none"
          :style="isCollapse? 'width: 97%': 'width: 87%' "
        >
          <!--<header class="header" style="position: fixed;top: 60px;width: 90%;">-->
          <!-- 顶部菜单 -->
          <el-tabs
            v-model="activeTab"
            type="card"
            class="tab-list"
            @tab-remove="tabRemove"
            @tab-click="pushRoute"
          >
            <el-tab-pane
              v-for="item in tabs"
              :key="item.path"
              :label="titleName(item.titleGroup)"
              :name="item.path"
              :closable="tabs.length !== 1"
            >
            </el-tab-pane>
          </el-tabs>
          <div class="tab-option">
            <el-button
              @click="clearTab"
              :disabled="tabs.length === 1"
              class="el-icon-error tab-clear"
            >
            </el-button>
          </div>
        </header>
        <!--<div class="main" style="margin-top: 40px;">-->
        <div class="main">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menus from "./menu";
import { mapState, mapMutations } from "vuex";
import mixin from "@/assets/js/mixin";
import api from '@/fetch/api'

export default {
  mixins: [mixin],
  components: {},
  data() {
    return {
      //菜单
      menus,
      //左侧菜单折叠
      isCollapse: false,
      //顶部活跃tab
      activeTab: "",
      localView: [],
      //当前主题索引
      currentThemeIndex: 0,
      widthFold: "64px",
      widthOpen: "251px",
      //        语言变化
      lang: {
        options: [
          {
            value: "zh-CN",
            label: "简体中文",
          },
          {
            value: "en-US",
            label: "English",
          },
          {
            value: "bahasa-Indonesia",
            label: "Bahasa",
          }, {
            value: 'original-text',
            label: 'Original Text'
          }
        ],
        value: sessionStorage.locale ? sessionStorage.locale : "zh-CN",
      },
      logo: "/logoShort.png",
      name: "商会咨询后台管理系统|Commerce Chamber Console Back-stage Management System",
      sysName: "",
      eventName:'',
    };
  },
  computed: {
    //tab集合 && 当前路由
    ...mapState({
      tabs: (state) => state.menu.headerMenu,
      currentRoute: (state) => state.menu.currentRoute,
    }),
    viewMenus() {
      var viewMenu = [];
      var routerMenu = [];
      let localView = JSON.parse(localStorage.getItem("viewRouters")).paths;
      localView.forEach((router) => {
        routerMenu.push(router.name);
      });
      // console.log('路由获取menu', routerMenu)

      //          原版，用cacheChamber来动态更改code从而控制menu显示， 但需要登录页刷新，pass
      /*this.menus.forEach(item=>{
          console.log('menu',item)
          if(item.code === 1){
              viewMenu.push(item)
          }else{
              console.log('cannot show',item.title)
          }
      });*/

      this.menus.forEach((item) => {
        // console.log("menu", item.name);
        if (routerMenu.includes(item.name)) {
          viewMenu.push(item);
        } else {
          console.log("cannot show", item.title);
        }
      });
      // console.log('可见menu', viewMenu)
      return viewMenu;
    },
  },
  watch: {
    currentRoute(val) {
      this.activeTab = val;
    },
  },
  created() {
    // console.log('created--localLanguage-->'+this.$t('localLanguage'));
    //后端语言设置，需要特殊处理
    if(sessionStorage.original==='yes'){
      this.setCookie("localLanguage","Original");
      //以上后端语言是源语言
    }else {
      this.setCookie("localLanguage",this.$t('localLanguage') );
    }
    this.setCookie("originalLanguage",sessionStorage.originalLanguage);
  },
  
  mounted() {
    var localView = JSON.parse(localStorage.getItem("viewRouters"));
    if (localView.paths.length === 2) {
      this.$store.commit("menu/clear");
      this.toPersonalCenter();
    } else {
      // console.log('localView.length', localView.paths.length)
    }
    this.activeTab = this.currentRoute;
    this.sysName = localStorage.SysName;
    this.load();
    
    /*setTimeout(()=>{
      this.textRoll()
    },2000)*/
    // this.textRoll()
    
  },
  methods: {
    ...mapMutations("menu", {
      clearTab: "clear",
      tabRemove: "sub",
    }),
    ...mapMutations("theme", {
      load: "load",
      change: "change",
    }),
    // 路由跳转
    pushRoute(tab) {
      this.$router.push({ path: tab.name });
    },
    //      变换语言
    clang: function (value) {
      this.setCookie("org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE",value==="zh-CN"?"zh_cn":"en");
      sessionStorage.locale = value;
      var dataFull = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      if(value==='zh-CN'){
        sessionStorage.translang = 'Chinese';
        sessionStorage.original='no';
          // 获取系统名称
          api.getSysName({
              currentAdmin:dataFull.id
          }).then(resp=>{
              // console.log('res ->',resp)
              localStorage.setItem('SysName',resp.msg)
              this.sysName = localStorage.SysName;
              location.reload();
          })

      }else if(value==='en-US'){
        sessionStorage.translang = 'English';
        sessionStorage.original='no';
          // 获取系统名称
          api.getSysName({
              currentAdmin:dataFull.id
          }).then(resp=>{
              // console.log('res ->',resp)
              localStorage.setItem('SysName',resp.msg)
              this.sysName = localStorage.SysName;
              location.reload();
          })
      }else if(value==='bahasa-Indonesia'){
        sessionStorage.translang = 'Bahasa';
        sessionStorage.original='no';
          // 获取系统名称
          api.getSysName({
              currentAdmin:dataFull.id
          }).then(resp=>{
              // console.log('res ->',resp)
              localStorage.setItem('SysName',resp.msg)
              this.sysName = localStorage.SysName;
              location.reload();
          })
      }else if(value==='original-text'){
        // console.log('value ->',value)
        sessionStorage.translang = 'Original';
        sessionStorage.original = 'yes';
        //     向后台发起请求，获取商会语言
        api.getChamberLanguage({}).then(res=>{
          var tenantAthenaChamberDTO = res.data
          if(tenantAthenaChamberDTO){
            sessionStorage.originalLanguage = tenantAthenaChamberDTO.language;
            // sessionStorage.locale = tenantAthenaChamberDTO.language;
          }
            // 获取系统名称
            api.getSysName({
                currentAdmin:dataFull.id
            }).then(resp=>{
                // console.log('res ->',resp)
                localStorage.setItem('SysName',resp.msg)
                this.sysName = localStorage.SysName;
                location.reload();
            })
        })
      }
    },

    //title中英文转换
    /*titleName(title) {
      //split时应该判断空指针和数组越界
      if (title) {
        let name = title.split("|");
        if (name.length > 1) {
          title = sessionStorage.locale === "en-US" ? name[1] : name[0];
        }
      }
      return title;
    },*/
    titleName(title) {
      // console.log("title,sessionStorage.locale ->",title,sessionStorage.locale);
      if (title) {
        let language = ''
        sessionStorage.original==='yes'? language = sessionStorage.originalLanguage: language = sessionStorage.locale
        switch (language) {
          case "zh-CN":
            return title.title;
          case "en-US":
            return title.enTitle;
          case "bahasa-Indonesia":
            return title.idTitle;
        }
      }
    },

    //      个人中心
    toPersonalCenter() {
      //        console.log('signin')
      this.$router.push({
        name: "personalCenter",
      });
    },
    
  //   目录滚动
    textRoll(){
      this.$nextTick(()=>{
        this.viewMenus.forEach(menu=>{
          const parent = this.$refs[`${menu.name}`]
          console.log('this.$refs[`${menu.name}`] ->',parent[0].offsetWidth)
          console.log('this.$refs[`${menu.name}`] ->',parent[0].offsetWidth>200)
          menu.children.forEach(child=>{
            // this.$nextTick(()=>{
              const children = this.$refs[`${child.path}`]
              console.log('childs[0].offsetWidth->',child.titleGroup.title,children[0].offsetWidth)
              console.log('childs[0].offsetWidth->',child.titleGroup.title,children[0].offsetWidth>140)
            // })
          })
        })
      })
    },
    
    /**
     * 当目录长度超过 150px 时，字符滚动
     * **/
  //   鼠标Hover判断长度
    ifTooLong(event){
      let obj = event.target.lastChild   // 获取到目录
      if(obj.offsetWidth>150){
        // this.eventName = obj.innerHTML
        obj.style.transition= 'transform 2s';
        let transWidth = obj.offsetWidth - 150
        console.log('transWidth1 ->',transWidth)
        obj.style.transform= 'translate(-'+transWidth+'px)';
      }
      // console.log('event ->',obj.style)
    },
    
  //   鼠标移出
    mouseLeave(event){
      let obj = event.target.lastChild   // 获取到目录
      if(obj.offsetWidth>150){
        obj.style.transition= 'transform 2s';
        obj.style.transform= 'translate(0px)';
      }
    },
    
  },
};
</script>
<style src="@/assets/theme/index.scss" lang="scss">
/*没有实现过渡动画效果，但是字终于没有延迟消失和卡顿了*/
/*.sidebar-enter, .sidebar-leave-to{
  transform: translateX(-166px);
}
.sidebar-enter-to, .sidebar-leave{
  transform: translateX(64px);
}
.sidebar-enter-active, .sidebar-leave-active{
  transition: 0.5s linear;
}*/
/*.sidebar {
  position: fixed;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.25s ease-out;
  width: 300px;
  z-index: 3;

  .el-scrollbar__wrap {
    overflow-y: hidden !important;
    overflow-x: scroll;
  }
}
.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53%, 0.4);
}

.sidebar:hover::-webkit-scrollbar-track {
  background: hsla(0, 0%, 53%, 0.1);
}*/
</style>
