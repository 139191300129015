export default {
  localLanguage: 'Bahasa',
  SysName: 'Sistem manajemen latar belakang konsultasi Kamar Dagang',
  AttorneyAthena: 'AttorneyAthena',
  ChamberVersion: 'Edisi Kamar Dagang',

  // 登录
  Login: 'Gabung',
  Logout: 'keluar',
  ReLogin: 'Login ulang',
  Juzi: 'ungu raksasa',
  InputAccount: 'Silakan masukkan akun',
  InputPassword: 'silakan masukkan kata sandi',
  InputPassword2: 'silakan masukkan kata sandi lagi',
  ErrorPassword2: 'Kata sandi yang dimasukkan dua kali tidak konsisten!',
  ForgetPassword: 'lupa kata sandinya',
  ConfirmPassword: 'Harap konfirmasi kata sandi Anda',
  InputValidCode: 'silahkan masukkan kode verifikasi',
  GetValidCode: 'dapatkan kode verifikasi',
  Second: 'Kedua',
  BackLogin: 'kembali ke halaman login',
  EmptyAccount: 'Akun tidak boleh kosong',
  Remember: 'ingat kata Sandi',
  PlsLogin: 'silahkan masuk terlebih dahulu',
  Back: 'mundur',
  EmailNotExist: 'kotak surat tidak ada',
  SysBusy: 'Sistem sedang sibuk, silakan coba lagi nanti',
  AccountForbid: 'Akun Anda telah diblokir',
  PasswordError: 'Username atau kata sandi salah',
  NoAccount: 'Akun ini tidak ada',
  ModifyMe:
    '' +
    '<p>Perubahan yang telah dilakukan pada akun ini memerlukan login ulang agar berlaku. </p>' +
    '<p>Apakah Anda yakin ingin mengonfirmasi perubahan dan login kembali?</p>',

  // 忘记密码
  InputRight: 'Silakan masukkan nomor di bawah ini',
  EmptyNum: 'Nomor tidak boleh kosong',
  GetVCode: 'dapatkan kode verifikasi',
  NewPassword: 'kata sandi baru',
  EmailVCode: 'Kode verifikasi email',
  WrongVCode: 'Kode verifikasi salah',
  EmptyVCode: 'kode verifikasi harus diisi',
  SecondTry: 'Coba lagi dalam hitungan detik',
  SuccessfulChange: 'Kata sandi berhasil diubah',
  PasswordLengthTips: 'Panjang kata sandi harus antara 8 hingga 16 karakter',

  // 个人中心
  PersonalCenter: 'pusat pribadi',
  AdminName: 'Nama',
  InvolveCountry: 'negara yang terlibat dalam melakukan bisnis',

  // 目录
  MyAccount: 'akun',
  Data: 'mengalir',

  SystemManagement: 'Manajemen Sistem',
  LawQA: 'kuis hukum',
  VideoOnline: 'video daring',
  ContractDownload: 'unduhan kontrak',
  CrossBoard: 'Pemberitahuan lintas batas',

  MessageManagement: 'manajemen pesan',
  SystemMessage: 'sistem Informasi',
  GroupMessage: 'pesan grup',

  UserManagement: 'manajemen pengguna',
  MemberManagement: 'Manajemen Anggota',

  AdminManagement: 'manajemen pengurus',
  AccountList: 'daftar akun',
  OperationRecord: 'catatan operasi',

  ContentManagement: 'manajemen konten',
  MainPageManagement: 'Manajemen halaman rumah',
  PostManagement: 'manajemen pos',
  FinanceManagement: 'Manajemen keuangan',
  IndustryReport: 'Laporan Industri',

  Annual: 'tahun',
  Quarterly: 'seperempat',
  Monthly: 'bulanan',
  Weekly: 'minggu ini',
  Daily: 'hari itu',
  Coupon: 'Pengurangan amplop merah',
  Expense: 'Konsumsi',
  Download: 'unduh',
  DragUpload: 'Seret file ke sini atau',
  ClickUpload: 'Klik untuk mengunggah',
  UploadLimitPDF:
    'Hanya file pdf yang dapat diunggah dan tidak boleh melebihi 200MB',
  Publish: 'Volume yang diterbitkan',
  Receive: 'Penerimaan',
  CoopSuccess: 'kerjasama yang sukses',
  DonationPopulation: 'Jumlah donasi',
  DonationFund: 'dana donasi',
  Buyers: 'daerah pembeli',
  Sellers: 'daerah penjual',
  CommerceCoop: 'Mencari pasangan',
  AllExpense: 'Semua konsumsi',
  ConfirmUpload: 'Konfirmasi unggahan',
  SelectFile2: 'Pilih Berkas',
  UploadFile: 'Unggah Berkas',
  UploadFileTips:
    'Jika Anda perlu mengubah berkas yang sudah diunggah, silakan pilih berkas baru dan unggah, maka berkas lama akan otomatis digantikan oleh berkas baru',

  // 消息管理
  SendMsg: 'Kirim pesan',
  Title: 'judul',
  Content: 'isi',
  TitleLong: 'judul terlalu panjang',
  WordLimit: 'Batas kata:',
  ReadMore: 'Klik untuk membaca teks selengkapnya',
  InputTitle: 'Masukkan judul',
  InputContent: 'Masukkan konten',
  NoData: 'Tidak ada data',

  AllData: 'semua',
  Initiate: 'Memulai',
  Banned: 'dengan disabilitas',
  ExceptionData: 'abnormal',
  Unreviewed: 'Tidak ditinjau',
  CompanyName: 'Nama perusahaan',
  ContactPerson: 'kontak person',
  NickName: 'nama panggilan',
  RealName: 'nama sebenarnya',
  ContactNum: 'nomor kontak',
  CompanyAddress: 'Alamat perusahaan',
  InfoMsg: 'pesan pemberitahuan',
  Status: 'negara',
  Operation: 'beroperasi',
  Edit: 'sunting',
  DeleteData: 'menghapus',
  AddPerson: 'Tambahkan pengguna',
  EditPerson: 'mengedit pengguna',
  CreateTime: 'Waktu penciptaan',
  UploadData: 'impor',
  File: 'dokumen',
  ExportExcelData: 'ekspor',
  Search: 'mencari',
  InitialPassword: 'sandi awal',
  Confirm: 'Tentu',
  Cancle: 'Membatalkan',
  SelectFile: 'Pilih file',
  EmptyEmail: 'Email tidak boleh kosong',
  ValidEmail: 'silakan isi alamat email',
  EmailExist: 'Alamat email ini telah terdaftar',
  EmailAccountExist: 'Email atau akun telah terdaftar',
  FillTel: 'Nomor telepon tidak boleh kosong',
  TelExist: 'Nomor kontak ini sudah ada',
  TelNum: 'Telepon harus berupa nomor',
  EmptyName: 'nama tidak boleh kosong',
  EmptyPassword: 'Kata sandi awal tidak boleh kosong',
  EmptyPassword2: 'Silakan ulangi kata sandi awal',
  UnpairPassword: 'Kedua kata sandi yang dimasukkan tidak konsisten',
  EmptyInfo: 'Pesan notifikasi tidak boleh kosong',
  ComfirmDelete: 'konfirmasi untuk menghapus?',
  ComfirmDeleteRow:
    'Jika Anda menghapus akun ini, email tidak dapat digunakan untuk membuat akun lainnya. Apakah Anda yakin ingin melanjutkan penghapusan?',
  ComfirmInit: 'Apakah Anda yakin ingin mengaktifkan pengguna ini?',
  ComfirmBan: 'Apakah Anda yakin ingin menonaktifkan pengguna ini?',
  XlsOnly:
    'Hanya mendukung format xls/xlsx, dan kotak surat tidak dapat digandakan',
  M5BOnly: 'Ukuran template unggahan tidak boleh melebihi 5MB!',
  UploadSuccess: 'Berhasil diunggah',
  UploadCancel: 'Pengunggahan dibatalkan',
  DownloadModel: 'unduh templat',
  ConfirmExcel:
    'Harap pastikan bahwa format Excel yang diunggah adalah template yang disediakan oleh sistem yang digunakan, jika tidak, sistem akan membuat kesalahan, harap benar-benar mematuhinya',
  Freeze: 'Beku',

  // 管理员管理
  Authority: 'izin',
  Password: 'kata sandi',
  Visiable: 'bisa dilihat',
  Hide: 'Tak terlihat',
  EditOnly: 'dapat diedit tetapi tidak dapat dihapus',
  AllRight: 'semua izin',
  Management: 'mengelola',
  OperType: 'jenis operasi',
  OperContent: 'Konten operasi',
  OperDate: 'tanggal operasi',
  OperAdmin: 'Operator',
  Add: 'Tambahkan',
  ChooseDate: 'pilih tanggal',
  AddSuccess: 'Berhasil ditambahkan',
  NoRight: 'Tidak ada izin.',

  Chamber: 'Kamar Dagang',
  System: 'sistem',
  Avatar: 'avatar',
  Name: 'nama',
  UserAgreement: 'Perjanjian Pengguna',
  Privacy: 'Kebijakan pribadi',
  AboutUs: 'tentang kami',
  Carousel: 'tampilan korsel',
  alert:
    '如果商会认为其会员可以不遵守智汇律神PC端供应商默认公示的使用规则，\n' +
    '包括但不限于《当事人用户平台服务协议》、《隐私政策》及在智汇律神\n' +
    '系统内公示的弹框和提示信息。则商会可以在系统供应商交付的后台自主\n' +
    '更改其系统的《当事人用户平台服务协议》、《隐私政策》等，也有权要\n' +
    '求系统供应商按照其设计更改系统内公示的弹框和提示信息。此种情况下\n' +
    '，系统在商会当地国运营的合规性问题由商会单独承担责任。',
  HasFile: 'Klik file yang ada untuk mengunduh',
  FinalTime: 'Terakhir diubah',
  ImgOnly: 'Format gambar hanya bisa 【jpg】【png】【jpeg】!',
  MBOnly: 'Ukuran gambar avatar yang diunggah tidak boleh melebihi 2 MB!',
  EmptyInput: 'Harap masukkan nama',
  OneFileOnly: 'Hanya satu file yang diperbolehkan untuk diunggah',
  FileOnly: 'Format berkas hanya bisa 【pdf】!',

  // 帖子管理
  ProductType: 'tipe produk',
  ProductRequest: 'persyaratan produk',
  ProductDetail: 'Rincian Produk',
  reviewTime: 'Waktu peninjauan',
  ReportingTime: 'waktu pelaporan',
  Pass: 'lulus',
  AllDelete: 'Hapus dari sistem ini',
  Refuse: 'menolak',
  Auditing: 'untuk ditinjau',
  Passed: 'lulus',
  AutoRefused: 'kedaluwarsa',
  ManRefused: 'ditolak',
  AutoPass: 'otomatis lulus',
  ManPass: 'lulus manual',
  Sensitive: 'Ada kata-kata sensitif',
  ProjectIntro: 'Pengantar Proyek',
  CooperationMethod: 'metode kerjasama',
  MyRequest: 'permintaanku',
  RevokeAll: 'Cabut postingan ini dari semua kamar dagang',
  RevokeThis: 'Cabut postingan ini dari Kamar Dagang',
  RevokeThis2: 'Tarik posting ini dari semua sistem',
  Pre: 'Artikel sebelumnya',
  After: 'Berikutnya',
  Negotiable: 'harga bisa dinegosiasikan',
  NonNegotiable: 'harga tidak bisa ditawar',
  Deleted: 'dihapus',
  InvalidReport: 'Laporan ini sudah tidak valid',
  Withdraw: 'Pengguna menarik laporan',
  DoInvalidReport: 'Laporan tidak valid',
  ReportReason: 'Laporkan alasan',
  ConfirmDelete: `<p>Maukah Anda menghapus postingan ini？</p>
    <p>Setelah dihapus, baik klien maupun backend tidak akan dapat melihat postingan ini lagi</p>
    <p>Untuk mengkonfirmasi penghapusan, silakan klik 'Konfirmasi'</p>`,
  ConfirmDelete2: `<p>Apakah Anda yakin ingin menarik？</p>
    <p>Setelah ditarik, semua kamar dagang tidak akan dapat melihat kiriman ini lagi</p>
    <p>Silakan klik 'konfirmasi' untuk melanjutkan</p>`,
  RefusePost: 'menolak pos',
  From: 'dari',
  ValidTill: 'Berlaku hingga',
  PublishTime: 'Waktu rilis',
  WithdrawAlert:
    'Setelah pencabutan, laporan akan hilang sama sekali, apakah akan mengkonfirmasi pencabutan?',
  RefuseAlert:
    'Setelah penolakan, laporan akan hilang sama sekali, baik konfirmasi penolakan?',
  Attention: 'Petunjuk',
  Withdrew: 'Berhasil dibatalkan',
  Member: '(Anggota)',
  CoopRequest: 'Persyaratan kerja sama',
  TendPrice: 'Harga niat',
  SellPrice: 'Harga penjualan',
  DefaultRefuse: 'Menampilkan alasan pelaporan secara default.',
  Canceled: 'Dibatalkan',
  RefuseReason: 'Alasan penolakan',
  NonNecessary: 'Opsional',
  PostType: 'Jenis Pos',
  TheEnd: 'Itu adalah akhirnya',

  // 财务管理
  CreateActivity: 'Membuat acara',
  PayStart: 'Waktu mulai pendaftaran',
  PayEnd: 'Pembayaran selesai',
  Paying: 'Pendaftaran Dibuka',
  Paid: 'Pendaftaran ditutup',
  PayCancel: 'Aktivitas dibatalkan',
  PayCancelPop: 'Apakah Anda yakin untuk membatalkan?',
  Time: 'waktu',
  Organizer: 'sponsor',
  PayAmount: 'Biaya pendaftaran',
  PayPerson: 'Jumlah pembayar',
  PayTime: 'Periode pendaftaran',
  Detail: 'detail',
  CancelActivity: 'membatalkan acara',
  ActivityEnd: 'Aktivitas berakhir',
  Close: 'tidak dibatalkan',
  AdditionInfo: 'Mengisi kembali',
  ActiveStart: 'Waktu mulai acara',
  ActiveEnd: 'Aktivitas berakhir',
  PayMethod: 'Tujuan biaya',
  PerPayment: 'Jumlah pembayaran individu',
  Theme: 'tema',
  Address: 'alamat',
  People: 'Peserta',
  Customize: 'menyesuaikan',
  FreeActivity: 'Aktivitas Gratis',
  EmptyTheme: 'Subjek tidak boleh kosong',
  EmptyAddress: 'Alamat tidak boleh kosong',
  EmptyGroup: 'Peserta tidak boleh kosong',
  EmptyDate: 'tanggal tidak boleh kosong',
  EmptyContent: 'isinya tidak boleh kosong',
  EmptyMoney:
    'Jumlah tidak boleh kosong, atau Anda dapat memilih untuk menyesuaikan',
  InputMoney: 'Masukkan jumlahnya',
  Customized: 'Kustom dipilih',
  AllGroup: 'Pilih semua anggota',
  EditActivity: 'kegiatan redaksi',
  Refund: 'Pengembalian dana',
  RefundAll: 'pengembalian dana penuh',
  AllDonation: 'Total pendapatan donasi:',
  AllIncome: 'Total pendapatan:',
  Population: 'Jumlah pendaftar :',
  CheckNotes: 'Lihat Pesan',
  DonationTime: 'Waktu donasi',
  DonationNotes: 'Pesan donasi',
  DonationAmount: 'Jumlah donasi',
  DonationUnit: 'Satuan donasi',
  DonationPerson: 'penyumbang',
  DonationAddress: 'Alamat Donatur',
  AllSame: 'Biaya Tahunan',
  FreeTime: 'Uji Coba Gratis',
  PayPeople: 'Jumlah peserta：',
  Latest: 'Terbaru',
  Expire: 'Berlaku hingga',
  Invoice: 'Pengaturan Berhasil',
  SetSuccessful: 'Pengaturan Berhasil',
  ActivityInvoice: 'Faktur Pembayaran Acara',
  MembershipInvoice: 'Faktur Biaya Keanggotaan',
  DonationInvoice: 'Faktur Pembayaran Donasi',
  Invoiced: 'Faktur Telah Dikeluarkan',
  ApplyPeople: 'Pemohon',
  NeedInvoice: 'Butuh faktur',
  Note: 'Catatan',
  SetInvoiced:
    'Status permohonan ini telah ditandai sebagai "Faktur Dikeluarkan"',
  Day: 'Hari',
  Month: 'Bulan',
  Year: 'Tahun',
  SetMembershipFee: 'Menetapkan Biaya Keanggotaan',
  StartTime: 'Tanggal mulai',
  EndTme: 'Tanggal berakhir',
  Day30: 'Periode pembayaran tidak boleh melebihi satu bulan.',
  SixMonths: `<p>Sekali periode pembayaran keanggotaan dikonfirmasi, ini akan memastikan kenyamanan penggunaan bagi anggota, dan dalam waktu 6 bulan, backend tidak akan lagi dapat mengatur periode pembayaran keanggotaan.</p>`,
  CurrentPeriod: 'Periode pembayaran keanggotaan saat ini adalah：',
  ConfirmToConfirm:
    "<p>Jika waktu sudah benar, klik 'Konfirmasi' untuk mengonfirmasi pengaturan.</p>",
  Reset: 'Atur ulang',
  ResetTime: 'Biaya keanggotaan telah diatur, waktu pengaturan berikutnya：',

  Writer: 'pengarang',
  Reject: 'ditolak',
  Cancel: 'menarik kembali',
  RevokeReport: 'menarik kembali',
  BriefIntroduction: 'Perkenalan',

  Delete: 'menghapus',
  AddSensitiveWords: 'Tambahkan kata-kata sensitif',
  SensitiveWords: 'Kata-kata sensitif',
  Creator: 'pendiri',
  EmptySensitive: 'Kata-kata sensitif tidak boleh kosong',

  Ssystem: 'Sistem konektivitas komersial',
  Fsystem: 'Sistem konektivitas hukum',

  // 用户分组
  ManageGroup: 'Mengelola Grup',
  BatchMoveTo: 'Pindahkan Secara Massal ke',
  BatchMoveToTip:
    'Silakan pilih beberapa akun anggota terlebih dahulu, lalu gunakan fungsi pemindahan massal',
  Group: 'Grup',
  MoveTo: 'Pindahkan ke',
  NotPayTip:
    'Anggota ini belum membayar biaya keanggotaan. Anda dapat memindahkannya setelah periode pembayaran biaya keanggotaan',
  GroupNamePlaceholder: 'Silakan masukkan nama grup',
  RemarkPlaceholder: 'Silakan tambahkan catatan',
  PeopleNumber: 'Jumlah Orang',
  MoveUp: 'Pindahkan Ke Atas',
  MoveDown: 'Pindahkan Ke Bawah',
  AddGroup: 'Tambah Grup',
  Verify: 'Verifikasi',
  DeleteGroupTip: 'Konfirmasi penghapusan grup?',
  DeleteSuccess: 'Penghapusan Berhasil',
  GroupNameEmpty: 'Nama grup tidak boleh kosong',
  RemarkEmpty: 'Catatan tidak boleh kosong',
  AddSuccessTip: 'Ditambahkan dengan sukses',
  EditSuccessTip: 'Suntingan berhasil',
  OperationSuccessTip: 'Operasi berhasil',
  SelectGroupTip: 'Silakan pilih grup',

  // 用户群发
  HairpinMessage: 'Pesan Pribadi',
  EditSend: 'Edit dan Kirim Ulang',
  AnewSend: 'Kirim Ulang',
  Publicity: 'Publik',
  PublisherName: 'Nama Penerbit',
  AdministratorName: 'Nama Administrator',
  CreationDate: 'Tanggal Pembuatan',
  RevocationTip:
    'Penarikan berarti pesan akan dihapus secara permanen dari sistem. Apakah Anda yakin ingin menariknya?',
  VerifyRevocation: 'Konfirmasi Penarikan',
  CancelRevocation: 'Batal Penarikan',
  RevocationSuccess: 'Penarikan Berhasil',
  GoBack: 'kembali',
  Picture: 'Gambar',
  Attachments: 'lampiran',
  DragFile: 'Seret file ke sini, atau',
  Extension: 'ekstensi dukungan',
  SendPossessor: 'Kirim ke Semua Orang',
  SendSpecificGroup: 'Kirim ke Kelompok Tertentu',
  Send: 'Kirim',
  PicturePreview: 'Pratinjau Gambar',
  PictureRestrict: 'Unggah hingga 6 gambar',
  FileRestrict: 'Unggah hingga 3 file',
  SendSuccess: 'Kirim Berhasil',
  MyMember: 'Anggota',
  SelectedMember: 'Anggota yang Dipilih',
  PleaseSelectMember: 'Silakan pilih anggota',
  GroupType: 'Grup',
  PleaseSelect: 'Silahkan pilih',
  ReadFull: 'Baca Artikel Lengkap',
  Publisher: 'Penerbit',
  Off: 'Tutup',
  MemberStatus: 'Status Baca/Belum Baca Anggota',
  SelectGroup: 'Pilih Kelompok',
  Read: 'Sudah Dibaca',
  Unread: 'Belum Dibaca',
};
