<template>
  <div id="app">

    <!--console.log('MAC',getMac()),-->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
                isRouterAlive: true,
            };
        },
        provide() {
            return {
                reload: this.reload,
            };
        },
        mounted(){
            sessionStorage.locale?sessionStorage.locale:sessionStorage.setItem('locale','zh-CN')
  
          var browerWidth = window.innerWidth; //浏览器可视宽度
          var baseWidth = 1920; //设计稿宽度
          var zoomValue = browerWidth / baseWidth; //缩放比例计算
          document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
  
          document.getElementById("app").style.transformOrigin = "0% 0%";
          document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
          
          window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
            browerWidth = window.innerWidth;
            zoomValue = browerWidth / baseWidth;
            document.getElementById("app").style.transformOrigin = "0% 0%";
            document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
          }
          // console.log('window.outerWidth ->',window.outerWidth)
        },
        methods: {
            reload() {
                this.isRouterAlive = false; //先关闭，
                this.$nextTick(function () {
                    this.isRouterAlive = true; //再打开
                });
            },
        },
    };
</script>


<style lang="scss">
@import url("~@/assets/el-style/style.css");
.el-dialog{
  min-width: 900px;
}
.btn-back{
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  color: #7b7b7b;
  cursor: pointer;
  width: fit-content;
  i{
    font-size: 25px;
    margin: 0 10px 20px 0;
  }
}
</style>
