import Layout from '@/common/Layout.vue';
import router from './index';

const _import = require('@/plugin/_import.js');

const temptData = {
  systemPermission: 0,
  messagePermission: 0,
  userPermission: 0,
  administratorPermission: 0,
  contentPermission: 0,
  postPermission: 1,
  financePermission: 1,
  industryReportPermission: 1,
};

// 如果没有本地缓存
if (localStorage.getItem('cacheChamber')) {
  var data = JSON.parse(localStorage.getItem('cacheChamber')).adminData;
} else {
  var data = temptData;
}

const frameIn = [
  // 登录
  /*{
    path: "/",
    redirect: { path: "/login" },
    meta: { title: "登录|Login",view:false},
    component: _import("login/index.vue"),
  },*/
  // 登录
  {
    path: '/',
    redirect: { path: '/login' },
    meta: {
      title: '登录',
      enTitle: 'Login',
      idTitle: 'Gabung',
      view: 0,
    },
    component: _import('login/index.vue'),
  },
  // 忘记密码
  {
    path: '/login/ForgetPassword',
    name: 'ForgetPassword',
    meta: {
      title: '忘记密码',
      enTitle: 'Forget password',
      idTitle: 'Forget password',
      view: 0,
    },
    component: _import('login/ForgetPassword.vue'),
  },
  // 等待界面
  {
    path: '/WaitingPage',
    name: 'waitingPage',
    meta: {
      title: '登录中',
      enTitle: 'Logging in',
      idTitle: 'Masuk',
      view: 1,
    },
    component: _import('WaitingPage/index.vue'),
  },
  //系统管理
  {
    path: '/menu',
    name: 'menu',
    redirect: { path: 'menu/AllContent' },
    // meta: { title: "系统管理|System Management", view:data.systemPermission},
    meta: {
      title: '系统管理',
      enTitle: 'System Management',
      idTile: 'Manajemen Sistem',
      view: 'systemPermission',
    },
    component: Layout,
    children: [
      {
        path: 'AllContent',
        name: 'AllContent',
        component: _import('menu/AllContent.vue'),
        meta: {
          isAuth: true,
          title: '全部',
          enTitle: 'All Data',
          idTile: 'All Data',
        },
      },
      {
        path: 'LawQA',
        name: 'LawQA',
        component: _import('menu/LawQA.vue'),
        meta: {
          isAuth: true,
          title: '法律问答',
          enTitle: 'Law QA',
          idTile: 'Kuis hukum',
        },
      },
      {
        path: 'VideoOnline',
        name: 'VideoOnline',
        component: _import('menu/VideoOnline.vue'),
        meta: {
          isAuth: true,
          title: '视频在线',
          enTitle: 'Video Online',
          idTile: 'Video daring',
        },
      },
      {
        path: 'ContractDownload',
        name: 'ContractDownload',
        component: _import('menu/ContractDownload.vue'),
        meta: {
          isAuth: true,
          title: '合同下载',
          enTitle: 'Contract Download',
          idTile: 'Unduhan kontrak',
        },
      },
      {
        path: 'CrossBoard',
        name: 'CrossBoard',
        component: _import('menu/CrossBoard.vue'),
        meta: {
          isAuth: true,
          title: '跨境须知',
          enTitle: 'Cross Board',
          idTile: 'Pemberitahuan lintas batas',
        },
      },
      {
        path: 'CommerceCoop',
        name: 'CommerceCoop',
        component: _import('menu/CommerceCoop.vue'),
        meta: {
          isAuth: true,
          title: '商务合作',
          enTitle: 'Commerce Cooperation',
          idTile: 'CKerjasama Bisnis',
        },
      },
      {
        path: 'MemberDonation',
        name: 'MemberDonation',
        component: _import('menu/MemberDonation.vue'),
        meta: {
          isAuth: true,
          title: '会员捐款',
          enTitle: "Members' Donation",
          idTile: 'Sumbangan anggota',
        },
      },
    ],
  },
  //消息管理
  {
    path: '/MessagerMenu',
    name: 'MessagerMenu',
    redirect: { path: 'MessagerMenu/GroupMessage' },
    // meta: { title: "消息管理|Message Management", view:data.messagePermission},
    meta: {
      title: '消息管理',
      enTitle: 'Message Management',
      idTile: 'Manajemen pesan',
      view: 'messagePermission',
    },
    component: Layout,
    children: [
      {
        path: 'SystemMessage',
        name: 'SystemMessage',
        component: _import('MessagerMenu/SystemMessage.vue'),
        meta: {
          isAuth: true,
          title: '系统消息',
          enTitle: 'System Message',
          idTile: 'Sistem Informasi',
        },
      },
      {
        path: 'GroupMessage',
        name: 'GroupMessage',
        component: _import('MessagerMenu/GroupMessage.vue'),
        meta: {
          isAuth: true,
          title: '用户群发',
          enTitle: 'Group Message',
          idTile: 'Grup pengguna',
        },
      },
      {
        path: 'MassMessage',
        name: 'MassMessage',
        component: _import('MessagerMenu/MassMessage.vue'),
        meta: {
          isAuth: true,
          title: '群发消息',
          enTitle: 'Mass Message',
          idTile: 'Pesan massal',
        },
      },
      {
        path: 'PrivateMessage',
        name: 'PrivateMessage',
        component: _import('MessagerMenu/PrivateMessage.vue'),
        meta: {
          isAuth: true,
          title: '私发消息',
          enTitle: 'Private Message',
          idTile: 'Pesan pribadi',
        },
      },
      {
        path: 'MemberStatus',
        name: 'MemberStatus',
        component: _import('MessagerMenu/MemberStatus.vue'),
        meta: {
          isAuth: true,
          title: '会员已读未读状态',
          enTitle: 'Member Status',
          idTile: 'Status keanggotaan',
        },
      },
    ],
  },
  //用户管理
  {
    path: '/UserMenu',
    name: 'UserMenu',
    redirect: { path: 'UserMenu/UserManagement' },
    // meta: { title: "用户管理|User Management", view:data.userPermission},
    meta: {
      title: '用户管理',
      enTitle: 'User Management',
      idTile: 'Manajemen pengguna',
      view: 'userPermission',
    },
    component: Layout,
    children: [
      {
        path: 'UserManagement',
        name: 'UserManagement',
        component: _import('UserMenu/UserManagement.vue'),
        meta: {
          isAuth: true,
          title: '会员管理',
          enTitle: 'Member Management',
          idTile: 'Manajemen Anggota',
        },
      },
      {
        path: 'UserGroup',
        name: 'UserGroup',
        component: _import('UserMenu/UserGroup.vue'),
        meta: {
          isAuth: true,
          title: '用户分组',
          enTitle: 'User Group',
          idTile: 'Pengelompokan pengguna',
        },
      },
    ],
  },
  //管理员管理
  {
    path: '/AdminMenu',
    name: 'AdminMenu',
    redirect: { path: 'AdminMenu/Account' },
    // meta: { title: "管理员管理|Admin Management", view:data.administratorPermission},
    meta: {
      title: '管理员管理|Admin Management',
      enTitle: 'Admin Management',
      idTile: 'Manajemen pengurus',
      view: 'administratorPermission',
    },
    component: Layout,
    children: [
      {
        path: 'Account',
        name: 'Account',
        component: _import('AdminMenu/Account.vue'),
        meta: {
          isAuth: true,
          title: '管理员列表',
          enTitle: 'Admins List',
          idTile: 'Daftar pengurus',
        },
      },
      {
        path: 'Operation',
        name: 'Operation',
        component: _import('AdminMenu/Operation.vue'),
        meta: {
          isAuth: true,
          title: '操作记录',
          enTitle: 'Operation Record',
          idTile: 'Catatan operasi',
        },
      },
    ],
  },
  //内容管理
  {
    path: '/ContentMenu',
    name: 'ContentMenu',
    redirect: { path: 'ContentMenu/MainPage' },
    // meta: { title: "内容管理|Content Management", view:data.contentPermission},
    meta: {
      title: '内容管理',
      enTitle: 'Content Management',
      idTile: 'Manajemen konten',
      view: 'contentPermission',
    },
    component: Layout,
    children: [
      {
        path: 'MainPage',
        name: 'MainPage',
        component: _import('ContentMenu/MainPage.vue'),
        meta: {
          isAuth: true,
          title: '主页管理',
          enTitle: 'MainPage Management',
          idTile: 'Manajemen halaman rumah',
        },
      },
      {
        path: 'SensitiveManagement',
        name: 'SensitiveManagement',
        component: _import('ContentMenu/SensitiveManagement.vue'),
        meta: {
          isAuth: true,
          title: '敏感词管理',
          enTitle: 'Sensitive Words Management',
          idTile: 'Manajemen kata sensitif',
        },
      },
    ],
  },
  //帖子管理
  {
    path: '/PostMenu',
    name: 'PostMenu',
    redirect: { path: 'PostMenu/UserPost' },
    meta: {
      title: '帖子管理',
      enTitle: 'Post Management',
      idTile: 'Manajemen pos',
      view: 'postPermission',
    },
    component: Layout,
    children: [
      {
        path: 'UserPost',
        name: 'UserPost',
        component: _import('PostMenu/UserPost.vue'),
        meta: {
          isAuth: true,
          title: '成员帖子管理',
          enTitle: "Users' Posts Management",
          idTile: 'Manajemen Posting Anggota',
        },
      },
      {
        path: 'ReceivePost',
        name: 'ReceivePost',
        component: _import('PostMenu/ReceivePost.vue'),
        meta: {
          isAuth: true,
          title: '接受帖子管理',
          enTitle: 'Receive Posts Management',
          idTile: 'Menerima manajemen pos',
        },
      },
      {
        path: 'ReportPost',
        name: 'ReportPost',
        component: _import('PostMenu/ReportPost.vue'),
        meta: {
          isAuth: true,
          title: '举报帖子管理',
          enTitle: 'Report Posts Management',
          idTile: 'Laporkan manajemen pos',
        },
      },
      {
        path: 'DetailedInformation',
        name: 'DetailedInformation',
        component: _import('PostMenu/DetailedInformation.vue'),
        meta: {
          isAuth: true,
          title: '帖子详情管理',
          enTitle: 'Report Posts Management',
          idTile: 'Manajemen detail posting',
        },
      },
    ],
  },
  //财务管理
  {
    path: '/FinanceMenu',
    name: 'FinanceMenu',
    redirect: { path: 'FinanceMenu/ActivityPayment' },
    meta: {
      title: '财务管理',
      enTitle: 'Finance Management',
      idTile: 'Manajemen keuangan',
      view: 'financePermission',
    },
    component: Layout,
    children: [
      {
        path: 'ActivityPayment',
        name: 'ActivityPayment',
        component: _import('FinanceMenu/ActivityPayment.vue'),
        meta: {
          isAuth: true,
          title: '活动缴费',
          enTitle: 'Activity Payment',
          idTile: 'Pembayaran aktivitas',
        },
      },
      /*{
        path: 'Donation',
        name: 'Donation',
        component: _import('FinanceMenu/Donation.vue'),
        meta: {
          isAuth: true,
          title: '会员捐款',
          enTitle: "Members' Donations",
          idTile: 'Sumbangan anggota',
        },
      },*/
      {
        path: 'MembershipFee',
        name: 'MembershipFee',
        component: _import('FinanceMenu/MembershipFee.vue'),
        meta: {
          isAuth: true,
          title: '会员缴费',
          enTitle: 'Membership payment',
          idTitle: 'Pembayaran keanggotaan',
        },
      },
      {
        path: 'PaymentDetail',
        name: 'PaymentDetail',
        component: _import('FinanceMenu/PaymentDetail.vue'),
        meta: {
          isAuth: true,
          title: '缴费详情',
          enTitle: 'Payment Detail',
          idTile: 'Rincian Pembayaran',
        },
      },
      {
        path: 'FeeDetail',
        name: 'FeeDetail',
        component: _import('FinanceMenu/FeeDetail.vue'),
        meta: {
          isAuth: true,
          title: '缴费详情',
          enTitle: 'Payment Detail',
          idTile: 'Rincian Pembayaran',
        },
      },
      /*{
        path: 'InvoiceRequest',
        name: 'InvoiceRequest',
        component: _import('FinanceMenu/InvoiceRequest.vue'),
        redirect: 'InvoiceRequest/ActivityInvoice',
        meta: {
          isAuth: true,
          title: '发票申请',
          enTitle: 'Invoice Request',
          idTile: 'Permohonan Faktur',
        },
        children: [
          {
            path: 'ActivityInvoice',
            name: 'ActivityInvoice',
            component: _import('FinanceMenu/ActivityInvoice.vue'),
            meta: {
              isAuth: true,
              title: '活动缴费发票',
              enTitle: 'Event Payment Invoice',
              idTile: 'Faktur Pembayaran Acara',
            },
          },
          {
            path: 'MembershipInvoice',
            name: 'MembershipInvoice',
            component: _import('FinanceMenu/MembershipInvoice.vue'),
            meta: {
              isAuth: true,
              title: '会员缴费发票',
              enTitle: 'Membership Fee Invoice',
              idTile: 'Faktur Biaya Keanggotaan',
            },
          },
          {
            path: 'DonationInvoice',
            name: 'DonationInvoice',
            component: _import('FinanceMenu/DonationInvoice.vue'),
            meta: {
              isAuth: true,
              title: '捐款缴费发票',
              enTitle: 'Donation Payment Invoice',
              idTile: 'Faktur Pembayaran Donasi',
            },
          },
        ],
      },*/
    ],
  },
  //行业报告
  /*{
    path: '/IndustryReport',
    name: 'IndustryReport',
    redirect: { path: 'IndustryReport/ReportManagement' },
    meta: {
      title: '行业报告',
      enTitle: 'Industry Report',
      idTile: 'Laporan Industri',
      view: 'industryReportPermission',
    },
    component: Layout,
    children: [
      {
        path: 'ReportManagement',
        name: 'ReportManagement',
        component: _import('IndustryReport/ReportManagement.vue'),
        meta: {
          isAuth: true,
          title: '会员报告',
          enTitle: "Members' Reports",
          idTile: 'Laporan anggota',
        },
      },
      {
        path: 'ReportDetail',
        name: 'ReportDetail',
        component: _import('IndustryReport/ReportDetail.vue'),
        meta: {
          isAuth: true,
          title: '报告详情',
          enTitle: 'Report Detail',
          idTile: 'Detail Laporan',
        },
      },
    ],
  },*/
  // 个人中心
  {
    path: '/home',
    name: 'home',
    // redirect: { path: "home/PersonalCenter" },
    meta: {
      title: '首页',
      enTitle: 'Home',
      idTitle: 'Halaman Depan',
      view: 1,
    },
    component: Layout,
    children: [
      {
        path: 'home/PersonalCenter',
        name: 'personalCenter',
        meta: {
          title: '个人中心',
          enTitle: 'Personal Center',
          idTile: 'Pusat pribadi',
        },
        component: _import('home/PersonalCenter.vue'),
      },
    ],
  },
];
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      enTitle: 'Login',
      idTitle: 'Gabung',
      view: 0,
    },
    component: _import('login/index.vue'),
  },
];

export default [...frameIn, ...frameOut];
